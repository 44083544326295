import React, { useState, useEffect } from "react";
import Page from "./page";
import { obtenerMandantes } from "../requestMandante";
import XLSX from "xlsx";

function Index(props) {
	const [Mandantes, SetMandantes] = useState();

	useEffect(() => {
		obtenerMandantes()
			.then(mandantes => SetMandantes(mandantes))
			.catch(error => SetMandantes([]));
	}, []);

	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Mandantes.map(m => ({
			nombre: m.nombre,
			rut: m.rut_formateado,
			responsable: m.responsable_actual ? m.responsable_actual.nombre_completo : "Sin Responsable",
			tipo: m.tipo,
			giro: m.giro,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Mandantes");
		XLSX.writeFile(wb, "mandantes.xlsx");
	}

	return (
		<Page
			mandantes={Mandantes}
			handle_export={handleExport}
		/>
	);
}

export default Index;