import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from "@mdi/react";
import { Link } from 'react-router-dom';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Tooltip, Zoom } from "@material-ui/core";
import { mdiAlphaCBox, mdiAlphaMBox, mdiAlphaSBox, mdiAlphaGBox, mdiAlphaRBox, mdiAlphaEBox } from "@mdi/js";
import * as PERMISSIONS from '../../constants/permissions';
import { useSelector } from 'react-redux';
const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
	menuButton: {
		marginRight: 26,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerMargin: {
		marginTop: "64px"
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflow: "hidden",
		width: theme.spacing(0) + 1,
		// width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {

		},
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 8px',
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}
}));

export default function MainDrawer(props) {

	const { open, handleClose } = props;
	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));
	const classes = useStyles();

	useEffect(() => {

	}, [])

	return (
		<Fragment>
			<Drawer
				variant="permanent"
				anchor="left"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx(classes.drawerMargin,
						{
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
				}}
				onClose={handleClose}
				open={open}>
				<Divider />
				<List>
					{open && (
						//Texto que aparece cuando se activa el drawer.
						<ListItem>
							<Typography variant="subtitle2">Portafolio</Typography>
						</ListItem>
					)}

					{MenuItemInterno("Contratos", "/contratos", open, <Icon path={mdiAlphaCBox} size={1} />)}
					{MenuItemInterno("Mandantes", "/mandantes", open, <Icon path={mdiAlphaMBox} size={1} />)}
					{MenuItemInterno("Sociedades", "/sociedades", open, <Icon path={mdiAlphaSBox} size={1} />)}
					{MenuItemInterno("Gerencias", "/gerencias", open, <Icon path={mdiAlphaGBox} size={1} />)}
					{MenuItemInterno("Reponsables", "/responsables", open, <Icon path={mdiAlphaRBox} size={1} />)}

					{usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos.findIndex(a => a.nombre === PERMISSIONS.ENVIAR_ENCUESTA) &&
						MenuItemInterno("Encuestas", "/encuesta/contratos", open, <Icon path={mdiAlphaEBox} size={1} />)
					}

					<Divider />

					{usuarioSesion && usuarioSesion.permisos && usuarioSesion.permisos.findIndex(a => a.nombre === PERMISSIONS.CONFIGURAR_PLATAFORMA) &&
						MenuItemInterno("Configuración", "/configuracion", open, <SettingsIcon />)
					}
				</List>
			</Drawer>
		</Fragment>
	);
}

/**
 * Método encargado de generar un icono para LINKs internos.
 * @param {*} titulo Título del icono.
 * @param {*} ruta Ruta de referencia a página interna.
 * @param {*} openClose Estado del drawer (abierto o cerrado).
 * @param {*} icon Elemento icono para mostrar.
 */
function MenuItemInterno(titulo, ruta, openClose, icon) {
	return (
		<Tooltip title={titulo} disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
			<ListItem button component={Link} to={ruta}>
				<ListItemIcon >
					{icon}
				</ListItemIcon>
				{openClose && (
					<ListItemText primary={titulo} />
				)}
			</ListItem>
		</Tooltip>
	);
}

