import React, { useEffect } from "react";
import Page from "./page";
import { useContratosVigentes } from "../../contratos/hooks";
import { useSelector } from "react-redux";


function Index(props) {

	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));
	// const { loading, contratos } = useContratosVigentesRealTime();
	const { loading, contratos } = useContratosVigentes(usuarioSesion);
	

	useEffect(() => {
		// ObtenerContratos()
		// 	.then(contratos => SetContratos(contratos))
		// 	.catch(error => SetContratos([]));
		
	}, []);

	return (
		<Page
			data={contratos}
			loading={loading}
		/>
	);
}

export default Index;