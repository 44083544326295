import React from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { deletePermiso } from '../requestPermisos';
import { actionOpenDeletePermiso, actionRefreshTablePermiso } from '../actionsPermisos';
/**
 * Lógica de componente para eliminar permiso
 * @author Luis San Martín S.
 */

export default function EliminarPermiso(props) {

  const dispatch = useDispatch();
  const notistack = useSnackbar();
  const { open, permiso} = useSelector(state => ({
    open: state.reducerPermiso.openDeletePermiso,
    permiso: state.reducerPermiso.permisoSeleccionado
  }));
  /**
   * Cargar datos del formulario
   */
  const handleOnSubmit = async () => {

    
    if (permiso) {
      const key = notistack.enqueueSnackbar("Eliminado Permiso...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      deletePermiso(permiso._id)
        .then((response) => {
          if (response.status === 204) {

            dispatch(actionRefreshTablePermiso(true));
            dispatch(actionOpenDeletePermiso());
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar("Operanción exitosa: Permiso eliminado", {
              variant: 'success',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });
          } else {
            console.log("error", response);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el Permiso. Contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el Permiso. Contacte con soporte el equipo de soporte", {
            variant: 'error',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
          });
        });
    }


  }
  /**
   * Cerrar dialogo
   */
  const handleClose = async () => dispatch(actionOpenDeletePermiso());
  


  return (


    <Page
      open={open}
      handleClose={handleClose}
      handleOnSubmit={handleOnSubmit}
    />
  );
}