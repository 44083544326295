import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../constants/urls";

const BASE_URL = process.env.REACT_APP_DB_URL;

/**
 * Método encargado de obtener todas las gerencias.
 */
export async function obtenerGerencias() {
	try {
		let url = `${BASE_URL}/gerencias`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener una gerencia, por su ID.
 * @param {*} gerenciaID 
 */
export async function obtenerGerencia(gerenciaID) {
	try {
		let url = `${BASE_URL}/gerencias/${gerenciaID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una gerencia.
 * @param {*} gerencia Datos de la gerencia.
 */
export async function agregarGerencia(gerencia) {
	try {
		let url = `${BASE_URL}/gerencias`;
		let response = await Axios.post(url, gerencia);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una gerencia.
 * @param {*} gerencia Datos actualizados de la gerencia.
 */
export async function actualizarGerencia(gerencia) {
	try {
		let url = `${BASE_URL}/gerencias/${gerencia._id}`;
		let response = await Axios.put(url, gerencia);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar una gerencia, por su ID.
 * @param {*} gerenciaID ID de la gerencia.
 */
export async function eliminarGerencia(gerenciaID) {
	try {
		let url = `${BASE_URL}/gerencias/${gerenciaID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

//////////////////////////////////////////////////////////////////////////////////

/**
 * Método encargado de obtener las personas que pueden ser responsables de contratos/proyectos.
 */
export async function obtenerResponsables() {
	try {
		let url = `${BASE_URL}/responsables`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}