import React from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";




function Indicadores(props) {

  const {
    loadEncuestas,
    data
  } = props;


  return (

    <Grid
      container
      style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}
      direction="row"
      justify="flex-start"
      alignItems="flex-start">
      {(loadEncuestas) ?
        <CircularProgress />
        :
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid
            item
            md={9}
            container
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Total Encuestas
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.cantidadTotalEncuestas}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Respondidas
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.cantidadEncuestasRespondidas}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Pendientes
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.cantidadEncuestasPendientes}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Promedio
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.promedio.toFixed(1)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Tasa de respuesta
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.tasaDeRespuesta} %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Promotores
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.estadisticasEncuestasFianlizadas.promotores}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Neutros
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.estadisticasEncuestasFianlizadas.neutros}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={3}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Detractores
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {data?.estadisticasEncuestasFianlizadas.detractores}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            container
            direction="column"
            justify="flex-start"
            alignItems="center">
            <Grid item>
              <Typography variant="h5" gutterBottom>
                NPS
                </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                {data?.factorNps}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      }
    </Grid >



  )

}

export default Indicadores;