import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerContratos } from "../requestContrato";
import XLSX from "xlsx";
import Moment from "moment";

function Index(props) {
	const [Contratos, SetContratos] = useState();

	useEffect(() => {
		obtenerContratos()
			.then(contratos => {
				contratos = IncluirNombreResponsable(contratos);
				SetContratos(contratos);
			})
			.catch(error => SetContratos([]));
	}, []);

	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Contratos.map(c => ({
			codigo: c.codigo,
			nombre: c.nombre,
			gerencia: c.gerencia_ref.sigla,
			empresa: c.sociedad_ref.nombre,
			fecha_inicio: Moment(c.fecha_inicio).format("DD/MM/YYYY HH:MM"),
			fecha_estimada_termino: Moment(c.fecha_termino_estimada).format("DD/MM/YYYY HH:MM"),
			encargado: c.responsable_ref.persona.nombre_completo,
			mandante: c.mandante_ref.nombre,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Contratos");
		XLSX.writeFile(wb, "contratos.xlsx");
	}

	return (
		<Page
			contratos={Contratos}
			handle_export={handleExport}
		/>
	);
}

/**
 * Método encargado de incluir el nombre completo de los encargados.
 * @param {*} contratos 
 */
function IncluirNombreResponsable(contratos) {
	for (let i = 0; i < contratos.length; i++) {
		const responsable = contratos[i].responsable;
		if (responsable) {
			contratos[i].responsable.display_name = `${responsable.nombre} ${responsable.apellido_paterno} ${responsable.apellido_materno}`;
		}
	}
	return contratos;
}

export default Index;