import React from 'react';
import PageLoadind from "../../components/page_loading";

function Page(props) {
  let texto = "Redirigiendo a" + props.url ? props.url : "nuevo destino";
  return (
    <PageLoadind texto={texto} />
  );
}

export default Page;
