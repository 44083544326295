import Axios from "axios";

const URL_BASE = "https://api-portafolio.cydocs.cl";

/**
 * Método encargado de obtener un contrato.
 */
export const obtenerContratos = async () => {
	return new Promise((resolve, reject) => {
		Axios.get(`${URL_BASE}/contratos?is_centro_costo=false`, {})
			.then(res => {
				if (res.data) {
					//Si existen datos, se retornan.
					resolve(res.data);
				} else {
					//Si no existen datos, se retorna NULL.
					resolve(null);
				}
			})
			.catch(error => {
				//Si se generó un error, se muestra por consola y se retorna.
				console.error(error);
				reject(error);
			});
	});
}

export const obtenerContratosPorGerencia = async(gerencia)=>{
	return new Promise((resolve, reject) => {
		Axios.get(`${URL_BASE}/contratos?gerencia_ref=${gerencia}&is_centro_costo=false`, {})
			.then(res => {
				if (res.data) {
					//Si existen datos, se retornan.
					resolve(res.data);
				} else {
					//Si no existen datos, se retorna NULL.
					resolve(null);
				}
			})
			.catch(error => {
				//Si se generó un error, se muestra por consola y se retorna.
				console.error(error);
				reject(error);
			});
	});
}


/**
 * Método encargado de obtener un contrato.
 */
export const obtenerContrato = async (id) => {
	return new Promise((resolve, reject) => {
		Axios.get(`${URL_BASE}/contratos/${id}?is_centro_costo=false`, {})
			.then(res => {
				if (res.data) {
					//Si existen datos, se retornan.
					resolve(res.data);
				} else {
					//Si no existen datos, se retorna NULL.
					resolve(null);
				}
			})
			.catch(error => {
				//Si se generó un error, se muestra por consola y se retorna.
				console.error(error);
				reject(error);
			});
	});
	
}

/**
 * Método encargado de agregar un contrato.
 * @param {*} contrato Contrato con los datos nuevos.
 */
export const AgregarContrato = async (contrato) => {
	try {
		let response = await Axios.post(`${URL_BASE}/contratos`, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un contrato.
 * @param {*} contrato Contrato con los datos actualizados.
 */
export const ActualizarContrato = async (contrato) => {
	try {
		let response = await Axios.put(`${URL_BASE}/contratos/${contrato._id}`, contrato);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un contrato.
 * @param {*} contratoID ID del contrato.
 */
export const EliminarContrato = async (contratoID) => {
	try {
		let response = await Axios.delete(`${URL_BASE}/contratos/${contratoID}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}