import { combineReducers } from "redux";
import usuarioSesion from './reducerDataUsuario';
import reducerPerfil from '../../containers/configuracion/perfiles/reducerPerfiles';
import reducerPermiso from '../../containers/configuracion/permisos/reducerPermisos';
import reducerRol from '../../containers/configuracion/roles/reducerRoles';

const reducer = combineReducers({
  usuarioSesion: usuarioSesion,
  reducerPerfil: reducerPerfil,
  reducerPermiso: reducerPermiso,
  reducerRol: reducerRol
});

export default reducer;