import React, { useEffect, useState } from "react";
import Page from "./page";
import { getAllRol } from "../requestRoles";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { actionOpenViewPerfilRol } from "../actionsRoles";
export default function ListadoRoles() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const cargarData = () => {

    const CancelToken = axios.CancelToken;
    let cancelRequest = null;
    let cancelToken = new CancelToken(function executor(c) {
      cancelRequest = c;
    });

    getAllRol({}, cancelToken)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data);
        } else if (response.status === 204) {
          setIsLoading(false);
          setData([]);
        } else {
          setIsLoading(false);
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setData([]);
      });

    return cancelRequest;
  };

  const onClickOpenAddPerfilRol = (rowData) => dispatch(actionOpenViewPerfilRol(true, rowData));

  useEffect(() => {
    setIsLoading(true);
    const cancelRequest = cargarData();
    return () => {
      if (cancelRequest) cancelRequest();
    }
  }, []);


  return (
    <Page
      data={data}
      isLoading={isLoading}
      onClickOpenAddPerfilRol={onClickOpenAddPerfilRol}
    />
  );
}