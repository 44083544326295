import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerMandante, actualizarMandante, AgregarResponsable, ObtenerResponsablesPorID } from "../requestMandante";
import { useHistory, useParams } from "react-router-dom";
import { array, object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Mandante, SetMandante] = useState();
	const [Responsables, SetResponsables] = useState();
	const [IsOpen, SetIsOpen] = useState(false);

	const { id: mandanteID } = useParams();
	const history = useHistory();
	const notistack = useSnackbar();

	useEffect(() => {
		obtenerMandante(mandanteID)
			.then(mandante => {
				SetMandante(mandante);
				SetResponsables(mandante.responsables);
			})
			.catch(error => {
				console.error(error);
				SetMandante({});
				SetResponsables([]);
			});
	}, []);

	useEffect(() => {
		if (Mandante) {
			ObtenerResponsablesPorID(Mandante._id)
				.then(responsables => {
					SetResponsables(responsables);
				})
				.catch(error => {
					console.error(error);
					SetResponsables([]);
				});
		}
	}, [Mandante, IsOpen]);

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		_id: Mandante ? Mandante._id : "",
		rut: Mandante ? Mandante.rut : "",
		nombre: Mandante ? Mandante.nombre : "",
		giro: Mandante ? Mandante.giro : "",
		tipo: Mandante ? Mandante.tipo : "",
		responsable_actual: Mandante && Mandante.responsable_actual ? Mandante.responsable_actual : null,
		responsable_nuevo: {
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
			cargo: "",
			contacto: {
				email: "",
				telefono: "",
				telefono_personal: "",
			},
		},
		responsables: Mandante ? Mandante.responsables : [],
	}

	/**
	 * Esquema de validación para el mandante.
	 */
	const validation = object().shape({
		rut: string()
			.required("El RUT es requerido."),
		nombre: string()
			.required("El nombre es requerido."),
		giro: string()
			.optional(),
		tipo: string()
			.oneOf(["Público", "Privado"], "El tipo es incorrecto.")
			.required("El tipo es requerido."),
		responsable_actual: object({
			nombre: string()
				.required("El nombre del responsable es requerido."),
			apellido_paterno: string()
				.required("El apellido paterno del responsable es requerido."),
			apellido_materno: string()
				.optional(),
			cargo: string()
				.required("El cargo del responsable es requerido."),
			contacto: object({
				email: string()
					.email("El formato de email es incorrecto.")
					.required("El email del responsable es requerido."),
				telefono: string()
					.optional(),
				telefono_personal: string()
					.optional()
			}),
		})
			.nullable()
			.required("El responsable actual es requerido."),
		responsable_nuevo: object().shape({
			nombre: string()
				// .required("El nombre del responsable es requerido"),
				.optional(),
			apellido_paterno: string()
				// .required("El apellido paterno del responsable es requerido."),
				.optional(),
			apellido_materno: string()
				.optional(),
			cargo: string()
				// .required("El cargo del responsable es requerido."),
				.optional(),
			contacto: object({
				email: string()
					.email("El formato de email es incorrecto.")
					// .required("El email del responsable es requerido."),
					.optional(),
				telefono: string()
					.optional(),
				telefono_personal: string()
					.optional()
			}),
		})
			.optional(),
		responsables: array()
			.min(1, "Al menos un responsable es requerido."),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, helper) => handleActualizar(values),
	});

	/**
	 * Handler para actualizar mandante.
	 */
	const handleActualizar = (values) => {
		actualizarMandante(values)
			.then(response => {
				console.log("Mandante actualizado exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Mandante actualizado exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar actualizar el mandante.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar actualizar el mandante.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/mandantes");
			});
	}

	/**
	 * Handler para salir del formulario.
	 */
	const handleSalir = () => {
		history.push("/mandantes");
	}

	/**
	 * Handler para agregar nuevo responsable.
	 */
	const handleAgregarResponsable = () => {
		AgregarResponsable(Mandante._id, formik.values.responsable_nuevo)
			.then(response => {
				console.log("Responsable agregado exitosamente.");
			})
			.then(() => {
				return ObtenerResponsablesPorID(Mandante._id);
			})
			.then(responsables => {
				SetResponsables(responsables);
			})
			.catch(error => {
				console.error(error);
				SetResponsables([]);
			})
			.finally(() => {
				handleHideDialog();
			});
	}

	/**
	 * Handler para mostrar dialog de responsable.
	 */
	const handleShowDialog = () => {
		SetIsOpen(true);
	}

	/**
	 * Handler para ocultar dialog de responsable.
	 */
	const handleHideDialog = () => {
		SetIsOpen(false);
	}

	return (
		<Page
			responsables={Responsables}
			handle_salir={handleSalir}
			formik={formik}
			is_open={IsOpen}
			handler_agregar_responsable={handleAgregarResponsable}
			handle_show_dialog={handleShowDialog}
			handle_hide_dialog={handleHideDialog}
		/>
	);
}

export default Index;