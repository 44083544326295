import React, { Fragment } from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "../../../components/material_table";

function Page(props) {
	const {
		sociedades,
		handle_export,
	} = props;

	const history = useHistory();

	let columns = [
		{
			title: "Código",
			field: "codigo",
		}, {
			title: "RUT",
			field: "rut_formateado",
		}, {
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Giro",
			field: "giro",
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => history.push(`/sociedad/${row._id}`),
			iconProps: { style: { color: "#757575" } },
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => alert("Eliminar Contrato"),
			iconProps: { style: { color: "#757575" } },
			hidden: true, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "add",
			tooltip: "Agregar",
			onClick: () => history.push("/sociedad"),
			isFreeAction: true,
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		},
	];

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Sociedades</Typography>
				<Typography color="textPrimary">Listado Sociedades</Typography>
			</Breadcrumbs>
			<MaterialTable
				title="Listado de Sociedades"
				is_loading={!sociedades}
				columns={columns}
				data={sociedades}
				actions={actions}
				export_function={handle_export}
			/>
		</Fragment>
	);
}

export default Page;