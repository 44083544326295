import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerResponsable, actualizarResponsable, obtenerUsuariosResponsables } from "../requestResponsable";
import { useHistory, useParams } from "react-router-dom";
import { number, object } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Responsable, SetResponsable] = useState();
	const [UsuariosResponsables, SetUsuariosResponsables] = useState();

	const { id: responsableID } = useParams();
	const history = useHistory();
	const notistack = useSnackbar();

	useEffect(() => {
		Promise.all([obtenerResponsable(responsableID), obtenerUsuariosResponsables()])
			.then(responses => {
				SetResponsable(responses[0]);
				SetUsuariosResponsables(responses[1]);
			})
			.catch(error => {
				console.error(error);
				SetResponsable({});
				SetUsuariosResponsables([]);
			});
	}, []);

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		_id: Responsable ? Responsable._id : "",
		persona: Responsable ? Responsable.persona : null,
		correlativo: Responsable ? Responsable.correlativo : "",
	}

	/**
	 * Esquema de validación para el responsable.
	 */
	const validation = object().shape({
		persona: object()
			.nullable()
			.required("Persona requerida."),
		correlativo: number()
			.integer("El correlativo debe ser un número entero.")
			.positive("El correlativo debe ser un número positivo.")
			.min(0, "El correlativo debe ser ${min} o un número mayor.")
			.max(99, "El correlativo debe ser a lo más ${max} o un número menor.")
			.required("El correlativo es requerido."),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, helper) => handleActualizar(values),
	});

	/**
	 * Handler para actualizar responsable.
	 */
	const handleActualizar = (values) => {
		actualizarResponsable(values)
			.then(response => {
				console.log("Responsable actualizado exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Responsable actualizado exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar actualizar el responsable.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar actualizar el responsable.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/responsables");
			});
	}

	/**
	 * Handler para salir del formulario.
	 */
	const handleSalir = () => {
		history.push("/responsables");
	}

	return (
		<Page
			usuarios_responsables={UsuariosResponsables}
			handle_salir={handleSalir}
			formik={formik}
		/>
	);
}

export default Index;