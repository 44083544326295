import React, { useState } from "react";
import Page from "./page";
import { agregarMandante } from "../requestMandante";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [IsOpen, SetIsOpen] = useState(false);

	const history = useHistory();
	const notistack = useSnackbar();

	//Alert para responsable agregado exitosamente.

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		rut: "",
		nombre: "",
		giro: "",
		tipo: "Público",
		responsable_actual: {
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
			cargo: "",
			contacto: {
				email: "",
				telefono: "",
				telefono_personal: "",
			},
		},
		responsables: [],
	}

	/**
	 * Esquema de validación para el mandante.
	 */
	const validation = object().shape({
		rut: string()
			.required("El RUT es requerido."),
		nombre: string()
			.required("El nombre es requerido."),
		giro: string()
			.optional(),
		tipo: string()
			.required("El tipo es requerido."),
		responsable_actual: object({
			nombre: string()
				.required("El nombre del responsable es requerido"),
			apellido_paterno: string()
				.required("El apellido paterno del responsable es requerido."),
			apellido_materno: string()
				.optional(),
			cargo: string()
				.required("El cargo del responsable es requerido."),
			contacto: object({
				email: string()
					.email("El formato de email es incorrecto.")
					.required("El email del responsable es requerido."),
				telefono: string()
					.optional(),
				telefono_personal: string()
					.optional()
			}),
		})
			.nullable()
			.required("El responsable es requerido.")
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		onSubmit: (values, helper) => handleAgregar(values),
	});

	/**
	 * Handler para agregar mandante.
	 */
	const handleAgregar = (values) => {
		values.responsables.push(values.responsable_actual);
		agregarMandante(values)
			.then(response => {
				console.log("Mandante agregado exitosamente", response);
				let mensajeExito = notistack.enqueueSnackbar("Mandante agregado exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar agregar el mandante.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar agregar el mandante.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/mandantes");
			});
	}

	/**
	 * Handler para limpiar el formulario.
	 */
	const handleLimpiar = () => {
		formik.resetForm();
	}

	/**
	 * Handler para mostrar dialog de responsable.
	 */
	const handleShowDialog = () => {
		SetIsOpen(true);
	}

	/**
	 * Handler para agregar responsable.
	 */
	const handleAgregarResponsable = () => {
		if (formik && formik.values && formik.values.responsable_actual && formik.values.responsable_actual.nombre && formik.values.responsable_actual.apellido_paterno && formik.values.responsable_actual.cargo && formik.values.responsable_actual.contacto && formik.values.responsable_actual.contacto.email) {
			console.log("Responsable agregado exitosamente.");
			let mensajeExito = notistack.enqueueSnackbar("Responsable agregado exitosamente.", {
				variant: "success",
				anchorOrigin: { vertical: "bottom", horizontal: "center" },
				action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
			});
			SetIsOpen(false);
		} else {
			let mensajeError = notistack.enqueueSnackbar("Debe ingresar toda la información requerida.", {
				variant: "warning",
				anchorOrigin: { vertical: "bottom", horizontal: "center" },
				action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
			});
		}
	}

	/**
	 * Handler para ocultar dialog de responsable.
	 */
	const handleCancelarAgregar = () => {
		formik.setFieldValue("responsable_actual.nombre", "");
		formik.setFieldValue("responsable_actual.apellido_paterno", "");
		formik.setFieldValue("responsable_actual.cargo", "");
		formik.setFieldValue("responsable_actual.contacto.email", "");
		SetIsOpen(false);
	}

	return (
		<Page
			handle_limpiar={handleLimpiar}
			formik={formik}
			is_open={IsOpen}
			handle_show_dialog={handleShowDialog}
			handle_agregar_responsable={handleAgregarResponsable}
			handle_cancelar_agregar={handleCancelarAgregar}
		/>
	);
}

export default Index;