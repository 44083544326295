export const ACTION_OPEN_ADD_PERFIL = "ACTION_OPEN_ADD_PERFIL";
export const ACTION_OPEN_EDIT_PERFIL = "ACTION_OPEN_EDIT_PERFIL";
export const ACTION_OPEN_DELETE_PERFIL = "ACTION_OPEN_DELETE_PERFIL";
export const ACTION_REFRESH_TABLE_PERFIL = "ACTION_REFRESH_TABLE_PERFIL";
export const ACTION_OPEN_ADD_PERMISO_PERFIL = "ACTION_OPEN_ADD_PERMISO_PERFIL";
export const ACTION_REFRESH_TABLE_PERMISOS_PERFIL = "ACTION_REFRESH_TABLE_PERMISOS_PERFIL";
export const ACTION_OPEN_VIEW_PERMISOS_PERFIL = "ACTION_OPEN_VIEW_PERMISOS_PERFIL";

export const actionOpenAddPerfil = (openAddPerfil = false) => ({
  type: ACTION_OPEN_ADD_PERFIL,
  openAddPerfil: openAddPerfil
});
export const actionOpenEditPerfil = (openEditPerfil = false, perfilSeleccionado = null) => ({
  type: ACTION_OPEN_EDIT_PERFIL,
  openEditPerfil: openEditPerfil,
  perfilSeleccionado: perfilSeleccionado
});
export const actionOpenDeletePerfil = (openDeletePerfil = false, perfilSeleccionado = null) => ({
  type: ACTION_OPEN_DELETE_PERFIL,
  openDeletePerfil: openDeletePerfil,
  perfilSeleccionado: perfilSeleccionado
});
export const actionRefreshTablePerfil = (refreshTablePerfil = false) => ({
  type: ACTION_REFRESH_TABLE_PERFIL,
  refreshTablePerfil: refreshTablePerfil
});
export const actionOpenViewPermisoPerfil = (openViewPermisoPerfil = false, perfilSeleccionado) => ({
  type: ACTION_OPEN_VIEW_PERMISOS_PERFIL,
  openViewPermisoPerfil: openViewPermisoPerfil,
  perfilSeleccionado: perfilSeleccionado
});
export const actionOpenAddPermisoPerfil = (openAddPermisoPerfil = false) => ({
  type: ACTION_OPEN_ADD_PERMISO_PERFIL,
  openAddPermisoPerfil: openAddPermisoPerfil
});
export const actionRefreshTablePermisosPerfil = (refreshTablePermisosPerfil = false) => ({
  type: ACTION_REFRESH_TABLE_PERMISOS_PERFIL,
  refreshTablePermisosPerfil: refreshTablePermisosPerfil
});