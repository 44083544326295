import React, { useState, useEffect } from "react";
import { obtenerMandante } from "../../../services/database/mandantes";
import { obtenerContrato } from "../../../services/database/contratos";

export const useMandante = (id) => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [mandante, setMandante] = React.useState([])


    useEffect(() => {
        obtenerMandante(id).then((result) => {
            setMandante(result)
            setLoading(false);
        }).catch(error => {
            setError(true)
        })
    }, [id])

    return {
        error,
        loading,
        mandante,
    }
}

export const useMandanteWithIdContrato = (idContrato) => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [mandante, setMandante] = React.useState(null)

    useEffect(() => {
        
        obtenerContrato(idContrato).then((resultContrato) => {
            obtenerMandante(resultContrato.mandante_ref._id).then((result) => {
                setMandante(result)
                setLoading(false);
            }).catch(error => {
                setError(true)
            })
        }).catch(error => {
            setError(true)
        })

    }, [idContrato])

    return {
        error,
        loading,
        mandante,
    }
}