import React, { Fragment } from "react";
import { Breadcrumbs, Paper, Grid, TextField, Typography, makeStyles, Box, Button, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";

function Page(props) {
	const {
		usuarios_responsables,
		handle_limpiar,
		formik,
	} = props;

	const classes = useStyle();
	const { values, handleChange, setFieldValue, submitForm, handleSubmit, isSubmitting, errors, touched } = formik;

	if (!usuarios_responsables) {
		//Si aún no cargan los datos.
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Responsables</Typography>
				<Link to="/responsables">Responsables</Link>
				<Typography color="textPrimary">Ingresar Responsable</Typography>
			</Breadcrumbs>
			<Paper className={classes.card}>
				<form onSubmit={handleSubmit}>
					{/* CAMPOS DEL RESPONSABLE */}
					<Grid container spacing={3}>
						<Grid item xs={6} xl={4}>
							{/* USUARIO RESPONSABLE */}
							<Autocomplete
								name="persona"
								options={usuarios_responsables}
								onChange={(event, value) => setFieldValue("persona", value ? value : null)}
								getOptionLabel={responsable => responsable.run_nombre_formateados}
								renderInput={(params) => (
									<TextField
										label="Usuario"
										variant="outlined"
										required
										error={touched.persona && errors.persona && Boolean(errors.persona)}
										helperText={touched.persona && errors.persona ? errors.persona : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* CORRELATIVO */}
							<TextField
								name="correlativo"
								label="Correlativo"
								value={values.correlativo}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.correlativo && errors.correlativo && Boolean(errors.correlativo)}
								helperText={touched.correlativo && errors.correlativo ? errors.correlativo : ""}
							/>
						</Grid>
					</Grid>
					<Box display="flex" flexDirection="row-reverse">
						<Button onClick={handle_limpiar} variant="outlined" color="primary" className={classes.button}>Limpiar</Button>
						<Button onClick={submitForm} variant="contained" color="primary" className={classes.button}>Agregar</Button>
					</Box>
				</form>
			</Paper>
		</Fragment>
	);
}

const useStyle = makeStyles((theme) => ({
	card: {
		padding: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(1),
	}
}));

export default Page;