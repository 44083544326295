import React, { Fragment } from "react";
import { CssBaseline, Container, makeStyles, Paper, Divider } from "@material-ui/core";
import HeaderEnvioCorreo from "./component/headerEnvioCorreo";
import EnvioCorreo from "./component/envioCorreo";
import { enviarEncuesta } from "../../../services/database/encuestas";
import TablaEncuestas from "./component/tablaRegistros"
import { useParams } from "react-router-dom";

const style = makeStyles(theme => ({
    main: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(4)
    }
}));


const handlerSendEncuesta = () => {
    enviarEncuesta()
}

function Page(props) {
    const classes = style();
    
    const {
        contactos,
        idContrato,
        mandante,
    } = props;

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="xl" className={classes.main} >
                <Paper square={true} style={{ borderRadius: "15px", paddingTop: 10 }}>
                    <HeaderEnvioCorreo
                        idContrato={idContrato} onClickSendEncuesta={handlerSendEncuesta} />
                    <Divider />
                    <EnvioCorreo idContrato={idContrato} contactos={contactos} mandante={mandante} />
                </Paper>
                <Paper square={true} style={{ background: "#731f1f", borderRadius: "15px", marginTop: 25, paddingTop: 10 }}>
                    <TablaEncuestas />
                </Paper>
            </Container>
        </Fragment>

    );
}

export default Page;
