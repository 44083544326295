import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import * as URLS from '../constants/urls';
import axios from 'axios';


const config = {
  apiKey: "AIzaSyDiRfYh144WXcsui3Ok_DfF-rnm84OgbTk",
  authDomain: "cydingenieria-proyectos.firebaseapp.com",
  databaseURL: "https://cydingenieria-proyectos.firebaseio.com",
  projectId: "cydingenieria-proyectos",
  storageBucket: "cydingenieria-proyectos.appspot.com",
  messagingSenderId: "908645375548",
  appId: "1:908645375548:web:17aaaf037808a343"
};

firebase.initializeApp(config);

export const auth = firebase.auth()
export const realTime = firebase.database()

export function onAuthStateChange(callback) {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      callback({ loggedIn: true, email: user.email });
    } else {
      callback({ loggedIn: false });
    }
  });
}

export async function onPermissionCheck() {
  var provider = new firebase.auth.GoogleAuthProvider();
  
  let currenToken = localStorage.getItem("csrfToken");
  provider.addScope('https://www.googleapis.com/auth/gmail.send');
  provider.addScope('https://www.googleapis.com/auth/gmail.compose');
  provider.addScope(' https://www.googleapis.com/auth/gmail.modify');
  provider.addScope(' https://mail.google.com');
  // let resultPermisos = await firebase.auth().signInWithPopup(provider);
  

  let resultToken = await axios.post(`${URLS.REDIRECT_BASE}/verifySessionCookie`, {}, {
    withCredentials: true,
    crossDomain: true,
    params: {
      _csrf: currenToken
    }

  })
  // console.log(resultToken)
  // if (resultToken.data.token) {
  //   firebase.auth().signInWithCustomToken(resultToken.data.token);
  // }
  console.log(resultToken)
  console.log(firebase.auth().currentUser)
  resultToken["user"] = firebase.auth().currentUser
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(resultToken);
    }, 1500)
  });

  

}

