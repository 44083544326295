import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import SubHeaderContratos from "./subHeaderContratos";
import SubHeaderIndicadores from "./subHeaderIndicadores";


function HeaderEnvioCorreo(props) {

  const {
    idContrato,
  } = props;


  return (

    <Grid
      container
      style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}
      direction="row"
      justify="flex-start"
      alignItems="flex-start">
      <Grid item md={5} xs={12}>
        <SubHeaderContratos idContrato={idContrato} />
      </Grid>
      <Grid item md={7} xs={12}>
        <SubHeaderIndicadores idContrato={idContrato} />
      </Grid>
    </Grid>



  )

}

export default HeaderEnvioCorreo;