//ENCUESTAS
export const VER_CONTRATOS_CYD = 'verContratosCyD';
export const ENVIAR_ENCUESTA = 'enviarEncuesta';
export const VER_CONTRATOS_ENCARGADO = "verContratosEncargado";

//CONTRATOS
export const VER_CONTRATOS = 'verContratos';
export const AGREGAR_CONTRATO = 'agregarContrato';
export const ACTUALIZAR_CONTRATO = 'actualizarContrato';
export const ELIMINAR_CONTRATO = 'eliminarContrato';

//MANDANTES
export const VER_MANDANTES = 'verMandantes';
export const AGREGAR_MANDANTE = 'agregarMandante';
export const ACTUALIZAR_MANDANTE = 'actualizarMandante';
export const ELIMINAR_MANDANTE = 'eliminarMandante';

//SOCIEDADES
export const VER_SOCIEDADES = 'verSociedades';
export const AGREGAR_SOCIEDAD = 'agregarSociedad';
export const ACTUALIZAR_SOCIEDAD = 'actualizarSociedad';
export const ELIMINAR_SOCIEDAD = 'eliminarSociedad';

//GERENCIAS
export const VER_GERENCIAS = 'verGerencias';
export const AGREGAR_GERENCIA = 'agregarGerencia';
export const ACTUALIZAR_GERENCIA = 'actualizarGerencia';
export const ELIMINAR_GERENCIA = 'eliminarGerencia';

//RESPONSABLES
export const VER_RESPONSABLES = 'verResponsables';
export const AGREGAR_RESPONSABLE = 'agregarResponsable';
export const ACTUALIZAR_RESPONSABLE = 'actualizarResponsable';
export const ELIMINAR_RESPONSABLE = 'eliminarResponsable';

//CONFIGURACIÓN
export const CONFIGURAR_PLATAFORMA = 'configurarPlataforma';