import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { auth, onAuthStateChange } from "./services/firebase";
import { accionSetUsuario } from "./redux/actions/dataUsuario";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import * as ROUTES from './constants/routes';
import * as PERMISSIONS from './constants/permissions';

import RedirectExternal from './containers/redirectExternal';
import Error401 from './containers/401';

import { CssBaseline } from '@material-ui/core';
import AppBar from "./components/appBar";
import MainDrawer from './components/mainDrawer';
import LoadPage from './containers/loadPage';

import { getUsuario } from './services/database/usuario';
import Configuracion from './containers/configuracion';
import * as URLS from './constants/urls';
import axios from 'axios';
import ScrollToTop from './components/ScrollToTop';
import { redirectToAccount } from './services/auth';

import ListaContratos from "./containers/contratos/lista";
import NuevoContrato from "./containers/contratos/nuevo";
import DetalleContrato from "./containers/contratos/detalle";

import ListaMandantes from "./containers/mandantes/lista";
import NuevoMandante from "./containers/mandantes/nuevo";
import DetalleMandante from "./containers/mandantes/detalle";

import ListaSociedades from "./containers/sociedades/lista";
import NuevoSociedad from "./containers/sociedades/nuevo";
import DetalleSociedad from "./containers/sociedades/detalle";

import ListaGerencias from "./containers/gerencias/lista";
import NuevoGerencia from "./containers/gerencias/nuevo";
import DetalleGerencia from "./containers/gerencias/detalle";

import ListaResponsables from "./containers/responsables/lista";
import NuevoResponsable from "./containers/responsables/nuevo";
import DetalleResponsable from "./containers/responsables/detalle";

import ListaEncuestas from "./containers/encuestas/lista";
import NuevoEncuesta from "./containers/encuestas/nuevo";
import DetalleEncuesta from "./containers/encuestas/detalle";
import ResultadoEncuestas from "./containers/encuestas/resultadoEncuestas";
import ListaContratosEncuestas from "./containers/encuestas/contratosEncuestas";
import EnviarEncuesta from './containers/encuestas/enviarEncuesta'

const drawerWidth = 300;
const appbarHeight = 18;

const useStyles = makeStyles((theme) => ({
	hide: {
		display: 'none',
	},
	main: {
		display: 'flex',
		background: "#ddd"
	},
	root: {
		display: 'flex',
	},
	content: {
		padding: theme.spacing(9, 1, 3, 1),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		minHeight: `calc(100vh - ${appbarHeight}px)`,
		width: "100vw",
	},
	contentUnShift: {
		width: "100vw",
	},
	contentShift: {
		width: `calc(100vw - ${drawerWidth}px)`,
	}
}));

const login = async () => {

	let currenToken = getQueryVariable("csrfToken");
	if (!currenToken) {
		currenToken = localStorage.getItem("csrfToken");
	}
	localStorage.setItem("csrfToken", currenToken);
	if (currenToken && currenToken !== "null" && currenToken !== "undefined") {

		await axios.post(`${URLS.REDIRECT_BASE}/verifySessionCookie`, {}, {
			withCredentials: true,
			crossDomain: true,
			params: {
				_csrf: currenToken
			}

		})
			.then(response => {

				if (response.data.token) {
					auth.signInWithCustomToken(response.data.token);
				}
			})
			.catch(error => {
				console.log(error);
				redirectToAccount()

			});
	} else {
		redirectToAccount()

	}
}

const getQueryVariable = function (variable) {
	const query = window.location.search.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		if (pair[0] === variable) { return pair[1]; }
	}
	return (false);
}

// export const MainContext = createContext({
// 	openMainDrawer: true,
// 	setOpenMainDrawer: () => { },
// 	usuarioSesion: null,
// 	accessError: false,
// 	setAccessError: () => { }
// });


function App(props) {

	const classes = useStyles();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);
	// const [login, setLogin] = useState(false);
	const [openMainDrawer, setOpenMainDrawer] = useState(false);
	const [accessError] = useState(false);

	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));


	const match = useRouteMatch('/resultado_encuesta/:id');

	const checkSession = isLogged => {
		try {
			if (isLogged && auth && auth.currentUser) {
				auth.currentUser.getIdTokenResult().
					then((idTokenResult) => {

						if (idTokenResult.claims && idTokenResult.claims.rol && idTokenResult.claims.rol.rol) return getUsuario(idTokenResult.claims.rol.rol, idTokenResult.claims.user_id);
						else return getUsuario("usuario_basico");
					})
					.then(async (response) => {
						//let claims = await auth.currentUser.getIdTokenResult()
						if (response.status === 200) {
							dispatch(accionSetUsuario({
								photoUrl: auth.currentUser.photoURL,
								nombre: auth.currentUser.displayName,
								email: auth.currentUser.email,
								permisos: response.data,
							}));
							setIsLoading(false);
						} else {
							dispatch(accionSetUsuario({
								photoUrl: auth.currentUser.photoURL,
								nombre: auth.currentUser.displayName,
								email: auth.currentUser.email,
								permisos: [],
							}));
							setIsLoading(false);
						}
					})
					.catch(e => console.log(e));
			} else {
				if (match != null && match.isExact) {
					setIsLoading(false);
					return <ResultadoEncuestas />
				} else {
					login();
				}


				// match ? <ResultadoEncuestas /> : login();

			}
		} catch (error) {
			console.log(error);
			// setLogin(true);
		}
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChange(checkSession);
		return () => {
			unsubscribe();
		}
	}, []);

	const handleOpenMainDrawer = () => {
		setOpenMainDrawer(true);
	}

	const handleCloseMainDrawer = () => {
		setOpenMainDrawer(false);
	}

	const privateRoute = ({ component: Component, permission, path, ...rest }) => (
		<Route
			path={path}
			{...rest}
			render={(props) => {
				if (accessError) return (<Redirect to='/accessError' />);
				if (!accessError && path === ROUTES.ACCESS_ERROR) return (<Redirect to='/' />);
				// if ((permission && usuarioSesion && (!usuarioSesion.permisos || !usuarioSesion.permisos.find(a => a.nombre === permission)))) {
				if ((permission && usuarioSesion && (!usuarioSesion.permisos || usuarioSesion.permisos.find(a => a.nombre === permission)))) {
					return withTemplate(<Redirect to='/' />);
				}
				return withTemplate(<Component {...props} />, rest.titulo);
			}}
		/>

	);

	const withTemplate = (component, titulo = null) => (
		<main className={classes.main}>
			<AppBar
				nombreModulo={titulo ? titulo : "Encuestas"}
				usuarioSesion={usuarioSesion}
				openMainDrawer={openMainDrawer}
				handleOpenMainDrawer={handleOpenMainDrawer}
				handleCloseMainDrawer={handleCloseMainDrawer}
			/>
			<MainDrawer
				usuarioSesion={usuarioSesion}
				open={openMainDrawer}
				handleClose={handleCloseMainDrawer}
			/>
			<div
				name="Content"
				style={{ backgroundColor: "#fafafa" }}
				className={clsx({
					[classes.content]: true,
					[classes.contentUnShift]: !openMainDrawer,
					[classes.contentShift]: openMainDrawer,
				})}>
				{component}
			</div>
		</main>
	);

	const mainApp = (
		<Fragment>
			<CssBaseline />
			<ScrollToTop />
			<Switch>
				{/* <Route exact={true} path={ROUTES.LOGIN} component={() => <LoginPage setLogin={setLogin} login={login} />} /> */}
				<Route exact={true} path={ROUTES.REDIRECT} component={() => <RedirectExternal />} />
				<Route exact={true} path={ROUTES.RESULTADO_ENCUESTAS} component={() => <ResultadoEncuestas />} />

				{privateRoute({
					component: Error401,
					path: ROUTES.ACCESS_ERROR,
					exact: true
				})}
				{privateRoute({
					component: ListaContratosEncuestas,
					// permission: PERMISSIONS.VER_CONTRATOS_CYD,
					path: ROUTES.LANDING,
					exact: true,
					titulo: "Encuestas",
				})}
				{privateRoute({
					component: ListaContratosEncuestas,
					// permission: PERMISSIONS.VER_CONTRATOS_CYD,
					path: ROUTES.VER_ENCUESTA_CONTRATOS,
					exact: true,
					titulo: "Encuestas",
				})}
				{privateRoute({
					component: EnviarEncuesta,
					permission: PERMISSIONS.ENVIAR_ENCUESTA,
					path: ROUTES.ENVIO_ENCUESTA_PROYECTO,
					exact: true,
					titulo: "Encuestas",
				})}
				{privateRoute({
					component: ListaContratos,
					permission: PERMISSIONS.VER_CONTRATOS,
					path: ROUTES.CONTRATOS,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: NuevoContrato,
					permission: PERMISSIONS.AGREGAR_CONTRATO,
					path: ROUTES.CONTRATO,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: DetalleContrato,
					permission: PERMISSIONS.ACTUALIZAR_CONTRATO,
					path: ROUTES.VER_CONTRATO,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: ListaMandantes,
					permission: PERMISSIONS.VER_MANDANTES,
					path: ROUTES.MANDANTES,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: NuevoMandante,
					permission: PERMISSIONS.AGREGAR_MANDANTE,
					path: ROUTES.MANDANTE,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: DetalleMandante,
					permission: PERMISSIONS.ACTUALIZAR_MANDANTE,
					path: ROUTES.VER_MANDANTE,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: ListaSociedades,
					permission: PERMISSIONS.VER_SOCIEDADES,
					path: ROUTES.SOCIEDADES,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: NuevoSociedad,
					permission: PERMISSIONS.AGREGAR_SOCIEDAD,
					path: ROUTES.SOCIEDAD,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: DetalleSociedad,
					permission: PERMISSIONS.ACTUALIZAR_SOCIEDAD,
					path: ROUTES.VER_SOCIEDAD,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: ListaGerencias,
					permission: PERMISSIONS.VER_GERENCIAS,
					path: ROUTES.GERENCIAS,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: NuevoGerencia,
					permission: PERMISSIONS.AGREGAR_GERENCIA,
					path: ROUTES.GERENCIA,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: DetalleGerencia,
					permission: PERMISSIONS.ACTUALIZAR_GERENCIA,
					path: ROUTES.VER_GERENCIA,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: ListaResponsables,
					permission: PERMISSIONS.VER_RESPONSABLES,
					path: ROUTES.RESPONSABLES,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: NuevoResponsable,
					permission: PERMISSIONS.AGREGAR_RESPONSABLE,
					path: ROUTES.RESPONSABLE,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: DetalleResponsable,
					permission: PERMISSIONS.ACTUALIZAR_RESPONSABLE,
					path: ROUTES.VER_RESPONSABLE,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: ListaEncuestas,
					path: ROUTES.ENCUESTAS,
					exact: true,
					titulo: "Portafolio",
				})}
				{privateRoute({
					component: NuevoEncuesta,
					path: ROUTES.ENCUESTA,
					exact: true,
					titulo: "Encuestas",
				})}
				{privateRoute({
					component: DetalleEncuesta,
					path: ROUTES.VER_ENCUESTA,
					exact: true,
					titulo: "Encuestas",
				})}
				{privateRoute({
					component: Configuracion,
					permission: PERMISSIONS.CONFIGURAR_PLATAFORMA,
					path: ROUTES.CONFIGURACION,
					exact: true,
					titulo: "Configuraciones",
				})}
			</Switch>
		</Fragment>
	)

	return (isLoading ? <LoadPage></LoadPage> : mainApp);
}

export default App;