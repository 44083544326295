import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Store from "./redux/store/store";
import { SnackbarProvider } from 'notistack';
import App from "./App";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ScrollToTop from "./components/ScrollToTop";

const theme = createMuiTheme({
	typography: {
		fontSize: 14,
		htmlFontSize: 17,
		fontFamily: ['Open Sans', 'sans-serif']
	},
	palette: {
		primary: {
			light: '#a64c46',
			main: '#731f1f',
			dark: '#430000',
			contrastText: '#ffffff',
		},
		secondary: {
			light: '#4f5b62',
			main: '#263238',
			dark: '#000a12',
			contrastText: '#ffffff',
		},
		text: {
			primary: "rgba(0, 0, 0, 0.87)",
			secondary: "rgba(0, 0, 0, 0.54)",
		},
	},
});

const MainContent = (
	<Provider store={Store}>
		<MuiThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<BrowserRouter>
					<SnackbarProvider>
						<App />
					</SnackbarProvider>
				</BrowserRouter>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	</Provider>
);

ReactDOM.render(MainContent, document.getElementById('root'));