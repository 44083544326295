import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { EncuestasContext } from "../../context/encuestaContext";



function SubHeaderIndicadores(props) {

  const {
    loadEncuestas,
  } = props;

  const [infoEncuestasProvider, setInfoEncuetas] = useContext(EncuestasContext);
  const [estadisticasEncuestas, setEstadisticasEncuestas] = useState({
    factorNps: 0,
    cantidadTotalEncuestas: 0,
    cantidadEncuestasRespondidas: 0,
    cantidadEncuestasPendientes: 0,
    tasaDeRespuesta: 0,
    promedio: 0
  })

  useEffect(() => {
    console.log("reload estadisticas")
    console.log(infoEncuestasProvider)
    if (infoEncuestasProvider) {
      setEstadisticasEncuestas(infoEncuestasProvider.estadisticasEncuestas)
    }

  }, [infoEncuestasProvider])
  return (

    <Grid
      container
      style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 0, paddingRight: 0 }}
      direction="row"
      justify="flex-start"
      alignItems="flex-start">
      {(loadEncuestas) ?
        <CircularProgress />
        :
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid
            item
            md={9}
            container
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid
              md={4}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Encuestas
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {estadisticasEncuestas?.cantidadTotalEncuestas}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={4}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Respondidas
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {estadisticasEncuestas?.cantidadEncuestasRespondidas}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={4}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Cantidad Pendientes
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {estadisticasEncuestas?.cantidadEncuestasPendientes}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={4}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Promedio
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {estadisticasEncuestas?.promedio.toFixed(1)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              md={4}
              xs={6}
              item
              container
              direction="column"
              justify="center"
              alignItems="center">
              <Grid item>
                <Typography variant="overline" display="block" gutterBottom>
                  Tasa de respuesta
              </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {estadisticasEncuestas?.tasaDeRespuesta} %
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            container
            direction="column"
            justify="flex-start"
            alignItems="center">
            <Grid item>
              <Typography variant="h5" gutterBottom>
                NPS
                </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                {estadisticasEncuestas?.factorNps}%
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      }
    </Grid >



  )

}

export default SubHeaderIndicadores;