import React, { useEffect } from 'react';
import Page from './page';
import { useHistory } from 'react-router-dom';
import * as URLS from '../../constants/urls';

function RedirectExternal(props) {

  const history = useHistory();

  const getQueryVariable = function (variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }



  useEffect(() => {

    const redirect = async () => {
      const destiny = getQueryVariable("destiny");
      var url = `${URLS.REDIRECT_BASE}/redirectToApp`;
      const contrato = localStorage.getItem("contrato");
      const proyecto = localStorage.getItem("proyecto");
      console.log(destiny);
      if (destiny) {
        url += `?redirect-url=${destiny}`;
        if (contrato && proyecto && contrato !== "undefined" && proyecto !== "undefined") {
          url += `&contrato=${contrato}&proyecto=${proyecto}`;
        }
        console.log(url);
        window.location.href = url;
      } else {
        history.replace("");
      }
    }
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page />
  );
}

export default RedirectExternal;