import React, { Fragment } from "react";
import { Breadcrumbs, Paper, Grid, TextField, Typography, makeStyles, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";

function Page(props) {
	const {
		handle_limpiar,
		formik,
		is_open,
		handle_show_dialog,
		handle_agregar_responsable,
		handle_cancelar_agregar,
	} = props;

	const classes = useStyle();
	const { values, handleChange, setFieldValue, submitForm, handleSubmit, isSubmitting, errors, touched } = formik;

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Mandantes</Typography>
				<Link to="/mandantes">Mandantes</Link>
				<Typography color="textPrimary">Ingresar Mandante</Typography>
			</Breadcrumbs>
			<Paper className={classes.card}>
				<form onSubmit={handleSubmit}>
					{/* CAMPOS DEL MANDANTE */}
					<Grid container spacing={3}>
						<Grid item xs={6} xl={4}>
							{/* NOMBRE */}
							<TextField
								name="nombre"
								label="Nombre"
								value={values.nombre}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
								helperText={touched.nombre && errors.nombre ? errors.nombre : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* RUT */}
							<TextField
								name="rut"
								label="RUT"
								value={values.rut}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.rut && errors.rut && Boolean(errors.rut)}
								helperText={touched.rut && errors.rut ? errors.rut : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* GIRO */}
							<TextField
								name="giro"
								label="Giro"
								value={values.giro}
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.giro && errors.giro && Boolean(errors.giro)}
								helperText={touched.giro && errors.giro ? errors.giro : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* BOTÓN PARA AGREGAR RESPONSABLE POPUP */}
							<Button onClick={handle_show_dialog} variant="outlined" fullWidth className={classes.button_agregar_responsable}>Agregar Responsable</Button>
							{touched.responsable_actual && errors.responsable_actual && (
								<Typography variant="caption" color="error" className={classes.mensaje_error_responsable}>Información de responsable faltante.</Typography>
							)}
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* TIPO */}
							<Autocomplete
								name="tipo"
								value={values.tipo}
								options={["Público", "Privado"]}
								onChange={(event, value) => setFieldValue("tipo", value ? value : "")}
								renderInput={(params) => (
									<TextField
										label="Tipo"
										variant="outlined"
										required
										error={touched.tipo && errors.tipo && Boolean(errors.tipo)}
										helperText={touched.tipo && errors.tipo ? errors.tipo : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Box display="flex" flexDirection="row-reverse">
						<Button onClick={handle_limpiar} variant="outlined" color="primary" className={classes.button}>Limpiar</Button>
						<Button onClick={submitForm} variant="contained" color="primary" className={classes.button}>Agregar</Button>
					</Box>
				</form>
			</Paper>

			{/* DIALOG PARA AGREGAR RESPONSABLE */}
			<Dialog open={is_open}>
				<DialogTitle>Responsable</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>Agregar información de contacto de cliente.</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.nombre"
								label="Nombre"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.nombre && errors.responsable_actual && errors.responsable_actual.nombre && Boolean(errors.responsable_actual.nombre)}
								helperText={touched.responsable_actual && touched.responsable_actual.nombre && errors.responsable_actual && errors.responsable_actual.nombre ? errors.responsable_actual.nombre : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.apellido_paterno"
								label="Apellido Paterno"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.apellido_paterno && errors.responsable_actual && errors.responsable_actual.apellido_paterno && Boolean(errors.responsable_actual.apellido_paterno)}
								helperText={touched.responsable_actual && touched.responsable_actual.apellido_paterno && errors.responsable_actual && errors.responsable_actual.apellido_paterno ? errors.responsable_actual.apellido_paterno : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.apellido_materno"
								label="Apellido Materno"
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.apellido_materno && errors.responsable_actual && errors.responsable_actual.apellido_materno && Boolean(errors.responsable_actual.apellido_materno)}
								helperText={touched.responsable_actual && touched.responsable_actual.apellido_materno && errors.responsable_actual && errors.responsable_actual.apellido_materno ? errors.responsable_actual.apellido_materno : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.cargo"
								label="Cargo"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.cargo && errors.responsable_actual && errors.responsable_actual.cargo && Boolean(errors.responsable_actual.cargo)}
								helperText={touched.responsable_actual && touched.responsable_actual.cargo && errors.responsable_actual && errors.responsable_actual.cargo ? errors.responsable_actual.cargo : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.contacto.email"
								label="Email"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.contacto.email && errors.responsable_actual && errors.responsable_actual.contacto.email && Boolean(errors.responsable_actual.contacto.email)}
								helperText={touched.responsable_actual && touched.responsable_actual.contacto.email && errors.responsable_actual && errors.responsable_actual.contacto.email ? errors.responsable_actual.contacto.email : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.contacto.telefono"
								label="Teléfono"
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.contacto.telefono && errors.responsable_actual && errors.responsable_actual.contacto.telefono && Boolean(errors.responsable_actual.contacto.telefono)}
								helperText={touched.responsable_actual && touched.responsable_actual.contacto.telefono && errors.responsable_actual && errors.responsable_actual.contacto.telefono ? errors.responsable_actual.contacto.telefono : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_actual.telefono_personal"
								label="Teléfono Personal"
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.responsable_actual && touched.responsable_actual.telefono_personal && errors.responsable_actual && errors.responsable_actual.telefono_personal && Boolean(errors.responsable_actual.telefono_personal)}
								helperText={touched.responsable_actual && touched.responsable_actual.telefono_personal && errors.responsable_actual && errors.responsable_actual.telefono_personal ? errors.responsable_actual.telefono_personal : ""}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handle_agregar_responsable} variant="contained" color="primary">Agregar</Button>
					<Button onClick={handle_cancelar_agregar} variant="outlined" color="primary">Cancelar</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

const useStyle = makeStyles((theme) => ({
	card: {
		padding: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(1),
	},
	button_agregar_responsable: {
		height: "100%",
	},
	mensaje_error_responsable: {
		marginLeft: theme.spacing(2),
	},
}));

export default Page;