import Axios from "axios";

const URL_BASE = "https://api-portafolio.cydocs.cl";

/**
 * Método encargado de obtener los mandantes.
 */
export const ObtenerMandantes = async () => {
	try {
		let response = await Axios.get(`${URL_BASE}/mandantes-sample`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}
/**
 * Método encargado de obtener los mandantes.
 */
export const obtenerMandante = async (id) => {
	if (id) {
		return new Promise((resolve, reject) => {
			Axios.get(`${URL_BASE}/mandantes/${id}`, {})
				.then(res => {
					if (res.data) {
						//Si existen datos, se retornan.
						resolve(res.data);
					} else {
						//Si no existen datos, se retorna NULL.
						resolve(null);
					}
				})
				.catch(error => {
					//Si se generó un error, se muestra por consola y se retorna.
					console.error(error);
					reject(error);
				});
		})
	} else {
		return null;
	}
}

/**
 * Método encargado de agregar un mandante.
 * @param {*} mandante Mandante con los datos nuevos.
 */
export const AgregarMandante = async (mandante) => {
	try {
		let response = await Axios.post(`${URL_BASE}/mandantes`, mandante);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un mandante.
 * @param {*} mandante Mandante con los datos actualizados.
 */
export const ActualizarMandante = async (mandante) => {
	if (mandante) {
		return new Promise((resolve, reject) => {
			Axios.put(`${URL_BASE}/mandantes/${mandante._id}`, mandante)
				.then(res => {
					if (res.data) {
						//Si existen datos, se retornan.
						resolve(res.data);
					} else {
						//Si no existen datos, se retorna NULL.
						resolve(null);
					}
				})
				.catch(error => {
					//Si se generó un error, se muestra por consola y se retorna.
					console.error(error);
					reject(error);
				});
		})
	} else {
		return null;
	}


}

/**
 * Método encargado de eliminar un mandante.
 * @param {*} mandanteID ID del mandante.
 */
export const EliminarMandante = async (mandanteID) => {
	try {
		let response = await Axios.delete(`${URL_BASE}/mandantes/${mandanteID}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}