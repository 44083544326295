import React, { useEffect, useContext } from "react";
import { ObtenerEncuestasPorContrato, ObtenerEncuestasSegunResponsable, ObtenerEncuestasSegunCliente, ObtenerEncuesta, ObtenerEncuestasByGerencia, processDataEncuestas, ObtenerEncuestas, ObtenerEncuestasPorContratoAndProcesarEstadistica } from "../../../../services/database/encuestas";
import 'moment/locale/es';
import MomentJS from "moment";

import _ from 'lodash';
import { useSelector } from "react-redux";
const cloneDeep = require('clone-deep');



export const useEncuesta = (idEncuesta) => {

  const [encuesta, setEncuesta] = React.useState(null)
  const [errorEncuesta, setErrorEncuesta] = React.useState(false)
  const { usuarioSesion } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion
  }));

  useEffect(() => {

    ObtenerEncuesta(idEncuesta, usuarioSesion)
      .then((result) => {
        setEncuesta(result)
      }).catch(error => {
        setErrorEncuesta(error)
      })
  }, [idEncuesta])

  return {
    encuesta,
    errorEncuesta
  }

}

/**
 * 
 * @param {bool} allCyd 
 * @param {gerencia_id} gerencia 
 * @param {encargado_id} encargado 
 */
export const useEncuestasFilter = (gerencia = null, cliente = null, encargado = null, contrato = null) => {

  const [encuestas, setEncuestas] = React.useState(null)
  const [encuestasConst, setEncuestasConst] = React.useState(null)
  const [errorEncuesta, setErrorEncuesta] = React.useState(false)
  const [loadProcesaEncuestas, setLoadProcesaEncuestas] = React.useState(true)
  const arrayDeMeses = MomentJS.monthsShort()

  const { usuarioSesion } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion
  }));
  /**
   * antes de nontar el hooks llama todas la encuestas
   */
  useEffect(() => {

    const loadEncuestas = async () => {
      const encuestasData = await ObtenerEncuestas(usuarioSesion);
      if (encuestasData) {
        let encuestasTerminadas = encuestasData.filter(enc => enc.estado === "Terminada")
        let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
        let general = processDataEncuestasGeneral(encuestasData);
        setEncuestas({
          encuestas: encuestasData,
          encuestaPorMes: datos.resultadoPorMes,
          general: general
        })
      }

    }
    loadEncuestas();
  }, [])

  const procesarDataHistoricaPorMeses = (encuestas) => {

    const monthName = item => {
      var localLocale = MomentJS(item.fecha_creacion, 'YYYY-MM-DD');
      MomentJS.locale('es');
      localLocale.locale(false);
      return localLocale.format('YYYY-MMM');
    }
    const encuestasPorMes = _.groupBy(encuestas, monthName);
    let resultadoPorMes = [];
    let currentYear = MomentJS();
    let anos = [currentYear.format('YYYY'), currentYear.add(1, 'years').format('YYYY'), currentYear.add(2, 'years').format('YYYY')]

    anos.forEach((ano) => {
      arrayDeMeses.forEach((item) => {
        let dataProcesada = null;
        let key = ano + '-' + item
        if (encuestasPorMes[key]) {

          dataProcesada = processDataEncuestas(encuestasPorMes[key])

          let total = dataProcesada.estadisticasEncuestasFianlizadas.total
          let promotores = (100 * (dataProcesada.estadisticasEncuestasFianlizadas.promotores / total)).toFixed(1)
          let detractores = (100 * (dataProcesada.estadisticasEncuestasFianlizadas.detractores / total)).toFixed(1)
          let neutros = (100 * (dataProcesada.estadisticasEncuestasFianlizadas.neutros / total)).toFixed(1)

          resultadoPorMes.push({
            mes: item,
            ano: ano,
            Promotores: promotores,
            Neutros: neutros,
            Detractores: detractores,
            nps: (promotores - detractores).toFixed(1)
          })

        } else {
          resultadoPorMes.push({
            mes: item,
            ano: ano,

          })
        }
      })
    })



    return {
      resultadoPorMes: resultadoPorMes
    }
  }

  const processDataEncuestasGeneral = (encuestas) => {
    let dataProcesada = processDataEncuestas(encuestas)
    let arrayData = [];
    arrayData.push({
      nombre: "Promotores",
      valor: dataProcesada.estadisticasEncuestasFianlizadas.promotores,
      color: "#27ae60"
    })
    arrayData.push({
      nombre: "Neutros",
      valor: dataProcesada.estadisticasEncuestasFianlizadas.neutros,
      color: "#7f8c8d"
    })
    arrayData.push({
      nombre: "Detractores",
      valor: dataProcesada.estadisticasEncuestasFianlizadas.detractores,
      color: "#c0392b"
    })
    return arrayData;
  }

  useEffect(() => {
    setLoadProcesaEncuestas(true)
    if (gerencia !== "all_gerencias" && cliente !== null && encargado !== null && contrato === null) {//caso 3
      if (encuestasConst) {
        let resultFiler = encuestasConst.encuestas
          .filter(enc => enc.contrato_ref.mandante_ref === cliente)
          .filter(enc => enc.contrato_ref.responsable.run === encargado)
        let encuestasTerminadas = resultFiler.filter(enc => enc.estado === "Terminada")
        let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
        let general = processDataEncuestasGeneral(resultFiler);
        let resumen = processDataEncuestas(resultFiler)


        setEncuestas({
          encuestas: resultFiler,
          encuestaPorMes: datos.resultadoPorMes,
          general: general,
          resumen: resumen
        })
        setLoadProcesaEncuestas(false)

      } else {
        setErrorEncuesta("not_found_encuestas")
        setLoadProcesaEncuestas(false)
      }

    } else if (gerencia !== "all_gerencias" && cliente !== null && contrato === null) {//caso 2
      let resultFiler = []
      if (encuestasConst) {
        resultFiler = encuestasConst.encuestas
          .filter(enc => {
            return enc.contrato_ref.mandante_ref === cliente
          })
      }
      let encuestasTerminadas = resultFiler.filter(enc => enc.estado === "Terminada")
      let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
      let general = processDataEncuestasGeneral(resultFiler);
      let resumen = processDataEncuestas(resultFiler)

      setEncuestas({
        encuestas: resultFiler,
        encuestaPorMes: datos.resultadoPorMes,
        general: general,
        resumen: resumen
      })
      setLoadProcesaEncuestas(false)

    } else if (gerencia !== "all_gerencias" && cliente === null && encargado !== null && contrato === null) {//caso 8
      let resultFiler =[]
      if (encuestasConst) {
        resultFiler = encuestasConst.encuestas
        .filter(enc => enc.contrato_ref.responsable.run === encargado)
      }
      

      let encuestasTerminadas = resultFiler.filter(enc => enc.estado === "Terminada")
      let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
      let general = processDataEncuestasGeneral(resultFiler);
      let resumen = processDataEncuestas(resultFiler)

      setEncuestas({
        encuestas: resultFiler,
        encuestaPorMes: datos.resultadoPorMes,
        general: general,
        resumen: resumen
      })
      setLoadProcesaEncuestas(false)

    } else if (gerencia !== "all_gerencias" && contrato === null) {//caso 1
      if (gerencia) {
        ObtenerEncuestasByGerencia(gerencia, usuarioSesion).then((result) => {
          let encuestasTerminadas = []
          if(result){
            encuestasTerminadas = result.filter(enc => enc.estado === "Terminada")
          }
          
          let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
          let general = processDataEncuestasGeneral(result);
          let resumen = processDataEncuestas(result)

          setEncuestas({
            encuestas: result,
            encuestaPorMes: datos.resultadoPorMes,
            general: general,
            resumen: resumen
          })
          setEncuestasConst({
            encuestas: result,
            encuestaPorMes: datos.resultadoPorMes,
            general: general,
            resumen: resumen
          })
          setLoadProcesaEncuestas(false)
        }).catch(error => {
          console.log(error)
          setLoadProcesaEncuestas(false)
          setErrorEncuesta(error)
        })
      } else {
        setLoadProcesaEncuestas(false)
      }

    } else if (gerencia === "all_gerencias" && cliente !== null && encargado !== null && contrato === null) {//caso 6

      if (encuestas) {
        let resultFiler = encuestas.encuestas
          .filter(enc => enc.contrato_ref.mandante_ref === cliente)
          .filter(enc => enc.contrato_ref.responsable.run === encargado)
        let encuestasTerminadas = resultFiler.filter(enc => enc.estado === "Terminada")
        let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
        let general = processDataEncuestasGeneral(resultFiler);
        let resumen = processDataEncuestas(resultFiler)

        setEncuestas({
          encuestas: resultFiler,
          encuestaPorMes: datos.resultadoPorMes,
          general: general,
          resumen: resumen
        })
        setLoadProcesaEncuestas(false)
      } else {
        setLoadProcesaEncuestas(false)
      }

    } else if (gerencia === "all_gerencias" && cliente !== null && encargado === null && contrato === null) {//caso 5
      ObtenerEncuestasSegunCliente(cliente, usuarioSesion).then((result) => {
        if (result) {
          let encuestasTerminadas = result.filter(enc => enc.estado === "Terminada")
          let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
          let general = processDataEncuestasGeneral(result);
          let resumen = processDataEncuestas(result)

          setEncuestas({
            encuestas: result,
            encuestaPorMes: datos.resultadoPorMes,
            general: general,
            resumen: resumen
          })
          setLoadProcesaEncuestas(false)
        } else {
          setEncuestas({
            encuestas: [],
            encuestaPorMes: [],
            general: []
          })
          setLoadProcesaEncuestas(false)
        }
      }).catch(error => {
        console.log(error)
        setLoadProcesaEncuestas(false)
        setErrorEncuesta(error)
      })
    } else if (gerencia === "all_gerencias" && cliente === null && encargado !== null && contrato === null) {//caso 7
      ObtenerEncuestasSegunResponsable(encargado).then((result) => {
        if (result) {
          let encuestasTerminadas = result.filter(enc => enc.estado === "Terminada")
          let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
          let general = processDataEncuestasGeneral(result);
          let resumen = processDataEncuestas(result)

          setEncuestas({
            encuestas: result,
            encuestaPorMes: datos.resultadoPorMes,
            general: general,
            resumen: resumen
          })
          setLoadProcesaEncuestas(false)
        } else {
          setEncuestas({
            encuestas: [],
            encuestaPorMes: [],
            general: []
          })
          setLoadProcesaEncuestas(false)
        }
      }).catch(error => {
        console.error(error)
        setLoadProcesaEncuestas(false)
        setErrorEncuesta(error)
      })
    } else if (contrato !== null) {//caso 8
      ObtenerEncuestasPorContrato(contrato).then((result) => {

        if (result) {
          let encuestasTerminadas = result.filter(enc => enc.estado === "Terminada")

          let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
          let general = processDataEncuestasGeneral(result);
          let resumen = processDataEncuestas(result)

          setEncuestas({
            encuestas: result,
            encuestaPorMes: datos.resultadoPorMes,
            general: general,
            resumen: resumen
          })
          setLoadProcesaEncuestas(false)
        } else {
          setEncuestas({
            encuestas: [],
            encuestaPorMes: [],
            general: []
          })
          setLoadProcesaEncuestas(false)
        }


      }).catch(error => {
        console.error(error)
        setErrorEncuesta(error)
        setLoadProcesaEncuestas(false)
      })

    } else if (gerencia === "all_gerencias") {//caso 0
      ObtenerEncuestas(usuarioSesion).then((result) => {
        let encuestasTerminadas =[]
        if(result){
          encuestasTerminadas = result.filter(enc => enc.estado === "Terminada")
        }
        
        let datos = procesarDataHistoricaPorMeses(encuestasTerminadas)
        let general = processDataEncuestasGeneral(result);
        let resumen = processDataEncuestas(result)

        setEncuestas({
          encuestas: result,
          encuestaPorMes: datos.resultadoPorMes,
          general: general,
          resumen: resumen
        })
        setLoadProcesaEncuestas(false)

      }).catch(error => {
        setErrorEncuesta(error)
        setLoadProcesaEncuestas(false)
      })
    } else {
      setLoadProcesaEncuestas(false)
    }



  }, [gerencia, cliente, encargado, contrato])

  return {
    encuestas,
    errorEncuesta,
    loadProcesaEncuestas
  }

}




export const useEncuestas = (idContrato) => {

  // const [setInfoEncuetas] = useContext(EncuestasContext);

  const [errorEncuestas, setErrorEncuestas] = React.useState(false)
  const [loadingEncuestas, setLoadingEncuestas] = React.useState(true)
  const [infoEncuestas, setInfoEncuestas] = React.useState({
    estadisticasEncuestas: {
      factorNps: 0,
      cantidadTotalEncuestas: 0,
      cantidadEncuestasRespondidas: 0,
      cantidadEncuestasPendientes: 0,
      tasaDeRespuesta: 0,
      promedio: 0
    }
  })




  useEffect(() => {
    ObtenerEncuestasPorContratoAndProcesarEstadistica(idContrato)
      .then((result) => {
        let estadisticasEncuestas = processDataEncuestas(result);
        setInfoEncuestas(result)
      }).catch(error => {
        setErrorEncuestas(true)
      })
  }, [idContrato])

  useEffect(() => {
    return () => {
      console.log("cleaned up");
    };
  }, []);



  return {
    errorEncuestas,
    loadingEncuestas,
    infoEncuestas,
  }
}
