import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../constants/urls";

const BASE_URL = process.env.REACT_APP_DB_URL;

/**
 * Método encargado de obtener todos los responsables.
 */
export async function obtenerResponsables() {
	try {
		let url = `${BASE_URL}/responsables`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un responsable, por su ID.
 * @param {*} responsableID ID del responsable.
 */
export async function obtenerResponsable(responsableID) {
	try {
		let url = `${BASE_URL}/responsables/${responsableID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un responsable.
 * @param {*} responsable Datos del responsable.
 */
export async function agregarResponsable(responsable) {
	try {
		let url = `${BASE_URL}/responsables`;
		let response = await Axios.post(url, responsable);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una responsable.
 * @param {*} responsable Datos actualizados del responsable.
 */
export async function actualizarResponsable(responsable) {
	try {
		let url = `${BASE_URL}/responsables/${responsable._id}`;
		let response = await Axios.put(url, responsable);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar una responsable, por su ID.
 * @param {*} responsableID ID de la responsable.
 */
export async function eliminarResponsable(responsableID) {
	try {
		let url = `${BASE_URL}/responsables/${responsableID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

//////////////////////////////////////////////////////////////////////////

/**
 * Método encargado de obtener las personas que pueden ser responsables de contratos/proyectos.
 */
export async function obtenerUsuariosResponsables() {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/personas?is_responsable=${true}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}