import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';

/**
 * Vista de componente para eliminar permiso
 * @author Luis San Martín S.
 * @property {Boolean} open Indicador para abrir o cerrar dialogo
 * @property {function} handleClose Cerrar dialogo
 * @property {function} handleOnSubmit Evento submit del formulario
 */

function Page(props) {
    const {
        open,
        handleClose,
        handleOnSubmit
    } = props;

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">Eliminar Permiso</DialogTitle>
            <DialogContent>
                ¿Está seguro de eliminar el Permiso?
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleOnSubmit}
                >
                    Aceptar
                </Button>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default Page;
