import React, { useState, Fragment } from "react";
import { Dialog, TextField, Grid, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";



export default function Page(props) {
  const { formik, onClose, onEntered, open } = props;
  const { errors, touched } = formik;
  return (
    <Dialog
      open={open}
      onEntered={onEntered}
      maxWidth={"md"}
      fullWidth={true}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle id="alert-dialog-title">Agregar Permiso</DialogTitle>
        <DialogContent>

          <Grid container>
            <Grid item lg>
              <TextField
                name={`nombre`}
                variant="outlined"
                label={"Nombre"}
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
                helperText={touched.nombre && errors.nombre ? errors.nombre : ""}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg>
              <TextField
                name={`descripcion`}
                variant="outlined"
                label={"Descripción"}
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={touched.descripcion && errors.descripcion && Boolean(errors.descripcion)}
                helperText={touched.descripcion && errors.descripcion ? errors.descripcion : ""}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
          // disabled={!isValid || !values.excel}
          >
            Aceptar
          </Button>
          <Button onClick={onClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}