import React, { useEffect, useState } from "react";
import Page from "./page";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup'
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getAllPermiso } from "../../permisos/requestPermisos";
import { actionOpenAddPermisoPerfil, actionRefreshTablePermisosPerfil } from "../actionsPerfiles";
import { postPermisosPerfil } from "../requestPerfiles";

export default function AgregarPermisoPerfil() {

  const dispatch = useDispatch();
  const notistack = useSnackbar();
  const { open, perfil } = useSelector((state) => ({
    open: state.reducerPerfil.openAddPermisoPerfil,
    perfil: state.reducerPerfil.perfilSeleccionado
  }));
  const [permisos, setPermisos] = useState([]);
  const validationSchema = yup.object({
    // perfil: yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      permisos: ""
    },
    onSubmit: values => {
      const permisos = values.permisos ? values.permisos.map(a => a._id) :[];
      const key = notistack.enqueueSnackbar("Guardando Permisos...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      try {
        postPermisosPerfil(perfil._id, permisos)
          .then((response) => {
            if (response.status === 201) {
              notistack.closeSnackbar(key);
              const successkey = notistack.enqueueSnackbar("Operanción exitosa: Permisos guardados", {
                variant: 'success',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
              });
              dispatch(actionOpenAddPermisoPerfil());
              dispatch(actionRefreshTablePermisosPerfil(true));
            } else {
              console.log(response);
              notistack.closeSnackbar(key);
              const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar los permisos. Intente nuevamente y contacte con soporte el equipo de soporte", {
                variant: 'error',
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
              });
            }
          })
          .catch((e) => {
            console.log(e);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar los permisos. Contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          });

      }
      catch (e) {
        console.log(e);
        notistack.closeSnackbar(key);
        const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible guardar los permisos. Contacte con soporte el equipo de soporte", {
          variant: 'error',
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
        });
      }
    },
    validationSchema: validationSchema
  });

  const onClose = () => dispatch(actionOpenAddPermisoPerfil());

  const onEntered = () => {
    if (perfil) {
      getAllPermiso({})
        .then(response => {
          if (response.status === 200) {
            const permisos = response.data;
            const suggestions = [];
            for (let i = 0; i < permisos.length; i++) {
              const option = { label: permisos[i].nombre, value: i, _id: permisos[i]._id };
              if (perfil.permisos_ref && perfil.permisos_ref.find(a => a._id === permisos[i]._id)) continue;
              suggestions.push(option);
            }
            setPermisos(suggestions);
          } else if (response.status === 204) {
            notistack.enqueueSnackbar("No hay permisos cargados", {
              variant: 'warning',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              }
            });
            setPermisos([]);
          } else {
            notistack.enqueueSnackbar("Error al cargar los permisos. Intente de nuevo o contacte con soporte.", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              }
            });
            console.log(response);
          }
        })
        .catch(e => {
          console.log(e);
        });


    }
    formik.resetForm({
      permisos: [],
    });
  }

  return (
    <Page
      formik={formik}
      permisos={permisos}
      onClose={onClose}
      onEntered={onEntered}
      open={open}
    />
  );
}