import {
  ACTION_REFRESH_TABLE_PERFIL,
  ACTION_OPEN_ADD_PERFIL,
  ACTION_OPEN_DELETE_PERFIL,
  ACTION_OPEN_EDIT_PERFIL,
  ACTION_OPEN_VIEW_PERMISOS_PERFIL,
  ACTION_OPEN_ADD_PERMISO_PERFIL,
  ACTION_REFRESH_TABLE_PERMISOS_PERFIL
} from './actionsPerfiles';

const defaultState = {
  refreshTablePerfil: true,
  openAddPerfil: false,
  openDeletePerfil: false,
  openEditPerfil: false,
  perfilSeleccionado: null,
  openAddPermisoPerfil: false,
  openViewPermisoPerfil: false,
  refreshTablePermisosPerfil: false
};

export default function reducerPerfil(state = defaultState, {
  type,
  refreshTablePerfil,
  openAddPerfil,
  openDeletePerfil,
  openEditPerfil,
  perfilSeleccionado,
  openAddPermisoPerfil,
  openViewPermisoPerfil,
  refreshTablePermisosPerfil
}) {

  switch (type) {
    case ACTION_REFRESH_TABLE_PERFIL: {
      return { ...state, refreshTablePerfil };
    }
    case ACTION_OPEN_ADD_PERFIL: {
      return { ...state, openAddPerfil };
    }
    case ACTION_OPEN_DELETE_PERFIL: {
      return { ...state, openDeletePerfil, perfilSeleccionado: perfilSeleccionado };
    }
    case ACTION_OPEN_EDIT_PERFIL: {
      return { ...state, openEditPerfil, perfilSeleccionado: perfilSeleccionado };
    }
    case ACTION_REFRESH_TABLE_PERMISOS_PERFIL: {
      return { ...state, refreshTablePermisosPerfil };
    }
    case ACTION_OPEN_VIEW_PERMISOS_PERFIL: {
      return { ...state, openViewPermisoPerfil: openViewPermisoPerfil, perfilSeleccionado: perfilSeleccionado };
    }
    case ACTION_OPEN_ADD_PERMISO_PERFIL: {
      return { ...state, openAddPermisoPerfil: openAddPermisoPerfil };
    }
    default:
      return state;
  }
}