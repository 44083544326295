import React, { useEffect, useState } from "react";
import Page from "./page";
import { getPerfilesRol, deletePerfilesRol } from "../requestRoles";
import { useDispatch, useSelector } from "react-redux";
import { actionOpenViewPerfilRol, actionOpenAddPerfilRol, actionRefreshTablePerfilesRol } from "../actionsRoles";
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
// import { actionOpenAddPerfil, actionRefreshTablePerfil, actionOpenEditPerfil, actionOpenDeletePerfil } from "../actionsPerfiles";
export default function ListadoPerfilesRol() {

  const { open, rol, resfresh } = useSelector((state) => ({
    open: state.reducerRol.openViewPerfilRol,
    rol: state.reducerRol.rolSeleccionado,
    resfresh: state.reducerRol.refreshTablePerfilesRol
  }));
  const notistack = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onEntered = () => {
    setIsLoading(true);
    setData([]);
    getPerfilesRol(rol._id)
      .then((response) => {
        // dispatch(actionRefreshTablePerfil(false));
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data);
        } else if (response.status === 204) {
          setIsLoading(false);
          setData([]);
        } else {
          setIsLoading(false);
          setData([]);
        }
        dispatch(actionRefreshTablePerfilesRol());
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setData([]);
      });
  }

  const onClose = () => dispatch(actionOpenViewPerfilRol());

  const onClickOpenAddPerfilRol = () => dispatch(actionOpenAddPerfilRol(true));
  const onClickDeletePerfilRol = (rowData) => {
    try {
      deletePerfilesRol(rol._id, [rowData._id])
        .then((response) => {
          if (response.status === 204) {
            const successkey = notistack.enqueueSnackbar("Perfil Eliminado", {
              variant: 'success',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            onEntered();
          } else {
            console.log(response);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el perfil. Intente nuevamente y contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          }
        })
        .catch((e) => {
          console.log(e);
          const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el perfil. Contacte con soporte el equipo de soporte", {
            variant: 'error',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
          });
        });

    }
    catch (e) {
      console.log(e);
      const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el perfil. Contacte con soporte el equipo de soporte", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
      });
    }
  };
  // const onClickOpenDeletePerfil = (rowData) => dispatch(actionOpenDeletePerfil(true, rowData));
  // const onClickOpenEditPerfil = (rowData) => dispatch(actionOpenEditPerfil(true, rowData));

  useEffect(() => {
    if (resfresh && rol) onEntered();
  }, [resfresh, rol]);
  return (
    <Page
      data={data}
      isLoading={isLoading}
      onClose={onClose}
      onEntered={onEntered}
      open={open}
      onClickOpenAddPerfilRol={onClickOpenAddPerfilRol}
      onClickDeletePerfilRol={onClickDeletePerfilRol}
    />
  );
}