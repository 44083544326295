import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerContratos, agregarContrato, obtenerGerencias, obtenerResponsables, obtenerSociedades, obtenerMandantes } from "../requestContrato";
import { useHistory } from "react-router-dom";
import { date, number, object, string } from "yup";
import { useFormik } from "formik";
import Moment from "moment";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Correlativo, SetCorrelativo] = useState();
	const [Correlativos, SetCorrelativos] = useState();
	const [Gerencias, SetGerencias] = useState();
	const [Responsables, SetResponsables] = useState();
	const [Sociedades, SetSociedades] = useState();
	const [Mandantes, SetMandantes] = useState();

	const history = useHistory();
	const notistack = useSnackbar();

	useEffect(() => {
		Promise.all([obtenerContratos(), obtenerGerencias(), obtenerResponsables(), obtenerSociedades(), obtenerMandantes()])
			.then(responses => {
				SetCorrelativos(responses[0].map(c => c.codigo.split("-")[2]));
				SetGerencias(responses[1]);
				SetResponsables(responses[2]);
				SetSociedades(responses[3]);
				SetMandantes(responses[4]);
			})
			.catch(error => {
				console.error(error);
				SetCorrelativo("");
				SetGerencias([]);
				SetResponsables([]);
				SetSociedades([]);
				SetMandantes([]);
			});
	}, []);

	useEffect(() => {
		if (Correlativos) {
			let nuevoCorrelativo = ObtenerNuevoCorrelativo(Correlativos);
			SetCorrelativo(nuevoCorrelativo);
		}
	}, [Correlativos]);

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		nombre: "",
		codigo: {
			gerencia: "",
			responsable: "",
			correlativo: Correlativo ? Correlativo : "",
		},
		estado: "Activo",
		fecha_inicio: Moment(),
		//Sumar 1 mes
		fecha_termino_estimada: Moment().add(1, "month"),
		responsable_ref: "",
		gerencia_ref: "",
		mandante_ref: "",
		sociedad_ref: "",
	}

	/**
	 * Esquema de validación para el contrato.
	 */
	const validation = object().shape({
		nombre: string()
			.min(3, "El nombre debe tener al menos ${min} caracteres.")
			.max(25, "El nombre debe tener a lo más ${max} caracteres.")
			.required("El nombre es requerido."),
		codigo: object({
			gerencia: string()
				.length(3, "La gerencia incorrecta.")
				.required("La gerencia requerida."),
			responsable: string()
				.length(1, "El correlativo de responsable incorrecto.")
				.required("El correlativo de responsable requerido."),
			correlativo: number()
				.typeError("El correlativo de contrato debe ser número.")
				.integer("El correlativo de contrato debe ser un número natural.")
				.min(1, "El correlativo de contrato debe ser mayor a ${min}.")
				.max(99999, "El correlativo de contrato debe ser menor a ${max}.")
			// .required("El correlativo de contrato es requerido."),
		}),
		estado: string()
			.required("El estado es requerido."),
		fecha_inicio: date()
			.optional(),
		fecha_termino_estimada: date()
			.required("La fecha estima de término es requerida."),
		responsable_ref: string()
			.required("El responsable es requerido."),
		gerencia_ref: string()
			.required("La gerencia es requerida."),
		mandante_ref: string()
			.required("El mandante es requerido."),
		sociedad_ref: string()
			.required("La sociedad es requerida."),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		// enableReinitialize: true,
		onSubmit: (values, helper) => handleAgregar(values),
	});

	useEffect(() => {
		let filtro = null;
		formik.values.responsable_ref = "";
		if (formik && formik.values && formik.values.gerencia_ref) {
			let gerenciaID = formik.values.gerencia_ref
			let gerencia = Array.from(Gerencias).find(g => g._id === gerenciaID);
			filtro = `gerencia=${gerencia.sigla}`;
		}
		obtenerResponsables(filtro)
			.then(responsables => {
				if (responsables) {
					SetResponsables(responsables);
				} else {
					SetResponsables([]);
				}
			})
			.catch(error => {
				console.error(error);
				SetResponsables([]);
			});
	}, [formik.values.gerencia_ref]);

	/**
	 * Handler para agregar contrato.
	 */
	const handleAgregar = (values) => {
		let correlativo = values.codigo.correlativo ? values.codigo.correlativo : Correlativo;
		let check = Array.from(Correlativos).includes(correlativo);
		if (check) {
			//Error en caso de correlativo repetido.
			let mensajeCorrelativoRepetido = notistack.enqueueSnackbar("El correlativo ingresado ya está siendo utilizado.", {
				variant: "error",
				anchorOrigin: { vertical: "bottom", horizontal: "center" },
				action: <IconButton onClick={() => notistack.closeSnackbar(mensajeCorrelativoRepetido)}><Close /></IconButton>
			});
		} else {
			//Se construye y asigna el código de contrato.
			values.codigo = `${values.codigo.gerencia}-${values.codigo.responsable}-${correlativo}`;
			//Se agrega el contrato.
			agregarContrato(values)
				.then(response => {
					console.log("Contrato agregado exitosamente.", response);
					let mensajeExito = notistack.enqueueSnackbar("Contrato agregado exitosamente.", {
						variant: "success",
						anchorOrigin: { vertical: "bottom", horizontal: "center" },
						action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
					});
				})
				.catch(error => {
					console.error("Error al intentar agregar el contrato.", error);
					let mensajeError = notistack.enqueueSnackbar("Error al intentar agregar el contrato.", {
						variant: "error",
						anchorOrigin: { vertical: "bottom", horizontal: "center" },
						action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
					});
				})
				.finally(() => {
					history.push("/contratos");
				});
		}
	}

	/**
	 * Handler para limpiar el formulario.
	 */
	const handleLimpiar = () => {
		formik.resetForm();
	}

	return (
		<Page
			correlativo={Correlativo}
			gerencias={Gerencias}
			responsables={Responsables}
			sociedades={Sociedades}
			mandantes={Mandantes}
			handle_limpiar={handleLimpiar}
			formik={formik}
		/>
	);
}

/**
 * Método encargado de estimar el nuevo correlativo para el contrato.
 * @param {*} contratos 
 */
function ObtenerNuevoCorrelativo(contratos) {
	//Se obtienen los códigos de los contratos, se separa por "-" y se obtiene el numero.
	let correlativos = Array.from(contratos).map(c => Number(c));
	//Se calcula el mayor.
	let ultimoCorrelativo = Math.max.apply(null, correlativos);
	//Se retorna el siguiente del mayor.
	return ultimoCorrelativo + 1;
}

export default Index;