import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

/**
 * Componente para hacer la tabla con lostado de datos.
 * @param {*} title Título de la tabla.
 * @param {*} is_loading Flag para determinar si está cargando los datos.
 * @param {*} data Datos para mostrar.
 * @param {*} columns Columnas seleccionadas para mostrar, con RENDER si aplica.
 * @param {*} actions Acciones (botones) por fila y libres.
 * @param {*} components Modificación de componentes (opcional).
 * @param {*} export_function Función para exportar datos en Excel.
 */
function Index(props) {
	const {
		title,
		is_loading,
		data,
		columns,
		actions,
		components,
		export_function,
	} = props;

	const [PageSize, SetPageSize] = useState(20);
	const [PageSizes, SetPageSizes] = useState([]);

	useEffect(() => {
		let pageSizes = [10];
		if (data && data.length > 0) {
			pageSizes.push(Math.ceil(data.length / 10) * 1);
			pageSizes.push(Math.ceil(data.length / 10) * 2);
			pageSizes.push(Math.ceil(data.length / 10) * 5);
			pageSizes.push(Math.ceil(data.length / 10) * 10);
		}
		SetPageSizes(pageSizes);
	}, [data]);

	useEffect(() => {
		SetPageSize(PageSizes[0]);
	}, [PageSizes]);

	let options = {
		padding: "dense",
		pageSize: PageSize,
		pageSizeOptions: PageSizes,
		emptyRowsWhenPaging: false,
		columnsButton: true,
		exportButton: true,
		search: true,
		grouping: true,
		filtering: true,
		actionsColumnIndex: -1,
		headerStyle: {
			backgroundColor: "#731f1f",
			color: "#ffffff",
			textAlign: "center",
		},
		exportCsv: (columns, data) => { export_function() },
	}

	let localization = {
		header: {
			actions: "Opciones",
		},
		body: {
			emptyDataSourceMessage: is_loading ? "Cargando" : "Sin registros",
		},
		toolbar: {
			addRemoveColumns: "Agregar o Quitar",
			searchPlaceholder: "Buscar",
			searchTooltip: "Buscar",
			showColumnsTitle: "Mostrar Columnas",
			exportTitle: "Exportar",
			exportName: "Exportar Excel",
		},
		pagination: {
			labelDisplayedRows: "{from}-{to} de {count}",
			labelRowsSelect: "Filas",
			firstAriaLabel: "Primera Página",
			firstTooltip: "Primera Página",
			previousAriaLabel: "Página Anterior",
			previousTooltip: "Página Anterior",
			nextAriaLabel: "Siguiente Página",
			nextTooltip: "Siguiente Página",
			lastAriaLabel: "Última Página",
			lastTooltip: "Última Página",
		},
		grouping: {
			placeholder: "Arrastre los encabezados aquí para agrupar",
		}
	}

	let localComponents = {}

	return (
		<MaterialTable
			title={title}
			isLoading={is_loading}
			columns={columns}
			options={options}
			localization={localization}
			actions={actions}
			data={data}
			components={Object.assign(localComponents, components)}
			style={{ borderRadius: "15px" }}
		/>
	);
}

export default Index;