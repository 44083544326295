import React, { Fragment } from 'react';
import { Avatar, Box, Chip, Breadcrumbs, Typography } from '@material-ui/core';
import Moment from "moment";
import MaterialTable from '../../../components/material_table';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

function TablaContratos(props) {
	const {
		contratos,
		handle_export,
	} = props;

	const classes = style();
	const history = useHistory();

	let columns = [
		{
			title: 'Código Proyecto',
			field: 'codigo',
			grouping: false,
			cellStyle: {
				fontSize: '0.9em',
				width: '15px',
			},
			render: row => generarCodigoContratoView(row.codigo),
		}, {
			title: 'Gerencia',
			field: 'gerencia_ref.sigla',
			cellStyle: {
				width: '7px',
			},
			render: row => generarCodigoGerenciaView(row),
		}, {
			title: 'Empresa',
			field: 'sociedad_ref.nombre',
		}, {
			title: 'Contrato',
			field: 'nombre',
		}, {
			title: 'Fecha Inicio',
			field: 'fecha_inicio',
			render: row => generarFechaView(row, "fecha_inicio"),
		}, {
			title: 'Fecha Est. Término',
			field: 'fecha_termino_estimada',
			render: row => generarFechaView(row, "fecha_termino_estimada"),
		}, {
			title: 'Encargado',
			field: 'responsable_ref.persona.nombre_completo',
			render: row => getEncargadoView(row),
		}, {
			title: 'Mandante',
			field: 'mandante_ref.nombre',
			render: row => generarMandantesView(row),
		},
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => history.push(`/contrato/${row._id}`),
			iconProps: { style: { color: "#757575" } },
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => alert("Eliminar Contrato"),
			iconProps: { style: { color: "#757575" } },
			hidden: true, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: 'email',
			tooltip: 'Encuesta',
			onClick: (event, row) => history.push(`/envio_encuestas/${row._id}`),
			iconProps: { style: { color: "#757575" } },
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "add",
			tooltip: "Agregar",
			onClick: () => history.push("/contrato"),
			isFreeAction: true,
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		},
	];

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Contratos</Typography>
				<Typography color="textPrimary">Contratos</Typography>
			</Breadcrumbs>
			<MaterialTable
				title="Portafolio de Contratos"
				is_loading={!contratos}
				data={contratos}
				columns={columns}
				actions={actions}
				export_function={handle_export}
			/>
		</Fragment >
	);
}

/**
 * Método encargado de generar el CHIP con el código del contrato/proyecto.
 * @param {*} codigo Código del contrato/proyecto.
 */
function generarCodigoContratoView(codigo) {
	if (!codigo) {
		return (
			<Chip label={"Sin Código"} color="primary" />
		);
	} else {
		return (
			<Chip label={codigo} color="primary" />
		);
	}
}

/**
 * Método encargado de generar el CHIP con la sigla de la gerencia.
 * @param {*} data Datos del contrato.
 */
function generarCodigoGerenciaView(data) {
	let texto = "Sin Gerencia";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.gerencia_ref) {
		texto = data.gerencia_ref.sigla;
	}
	return (
		<Chip label={texto} variant="outlined" color="primary" />
	);
}

/**
 * Método encargado de generar el CHIP con foto y nombre del encargado.
 * @param {*} data Datos del contrato.
 */
function getEncargadoView(data) {
	let texto = "Sin Responsable";
	let foto = "";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.responsable_ref) {
		texto = data.responsable_ref.persona.nombre_completo;
		foto = data.responsable_ref.persona.photo;
	}
	return (
		<div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
			<Avatar alt="Foto Encargado" src={foto} />
			<Box fontSize={13} m={1}>
				{texto}
			</Box>
		</div>
	);
}

/**
 * Método encargado de formatear las fechas, si corresponde.
 * @param {*} fecha Fecha que será verificada y formateada. 
 */
function generarFechaView(data, propiedad) {
	let texto = "Indefinida";
	if (data && typeof data === "string") {
		texto = data;
	} else if (data && Moment(data[propiedad]).year() === 2100) {
		texto = "Indefinido";
	} else if (data && data[propiedad]) {
		texto = Moment(data[propiedad]).format("DD/MM/YYYY");
	}
	return texto;
}

/**
 * Método encargado de generar el CHIP para mandantes.
 * @param {*} data Datos del contrato.
 */
function generarMandantesView(data) {
	let texto = "Sin Gerencia";
	if (typeof data === "string") {
		texto = data;
	} else if (typeof data === "object" && data.mandante_ref) {
		texto = data.mandante_ref.nombre.length < 25 ? data.mandante_ref.nombre : data.mandante_ref.nombre.substring(0, 25) + '...'
	}
	return (
		<Chip label={texto} variant="outlined" color="primary" />
	);
}

const style = makeStyles(theme => ({
}));


export default TablaContratos;