import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Container,
  DialogActions,
  Grid,
  TextField,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { Formik } from "formik";
import * as Yup from "yup";
import { ActualizarMandante } from '../../../../services/database/mandantes';




const useStyles = makeStyles(theme => ({
  main: {
    // marginBottom: '50px',
    // paddingLeft: theme.spacing(9),
    // paddingTop: theme.spacing(11)
  },
  formControl: {
    // paddingTop: theme.spacing(1)
  },
  dialogPaper: {
    overflow: 'visible'
  },
  hidden: {
    display: "none"
  },
  textField: {

  }

}));


function FormularioContacto(props) {
  const classes = useStyles();
  const {
    openAgregarCliente,
    handleCloseModal,
    mandante,
    onMandanteChange
  } = props;

  const [formik] = React.useState(null);



  const handleChange = (e, { name, value }) => {
    formik.setFieldValue(name, value);
    e.persist();
  };

  const values = {
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    cargo: "",
    telefono: "",
    telefono_personal: "",
    email: "",
    

  }
  const validationSchemaUsuario = Yup.object({
    nombre: Yup.string().required("nombre Requerido").typeError("nombre Requerido"),
    apellido_paterno: Yup.string().required("nombre Requerido").typeError("apellido paterno Requerido"),
    cargo: Yup.string().required("cargo Requerido").typeError("cargo Requerido"),
    email: Yup.string().required("email Requerido").email().typeError("Email incorrecto"),
    
  })


  
  const handleOnSubmit = async (values, formikBag) => {

    let buildPerosna = {
      nombre: values.nombre,
      apellido_paterno: values.apellido_paterno,
      apellido_materno: values.apellido_materno,
      cargo: values.cargo,
      contacto: {
        telefono: values.telefono,
        telefono_personal: values.telefono_personal,
        email: values.email,
        
      }
    }


    mandante.responsables.push(buildPerosna);

    ActualizarMandante(mandante).then(response => {
      handleCloseModal()
      onMandanteChange(response._id)
    })



  }

  const handleResetForm = (values, formikBag) => {

  }

  return (

    <Dialog open={openAgregarCliente}  aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar Información de contacto de cliente</DialogTitle>
      <DialogContent>
        <Formik
          onReset={handleResetForm}
          onSubmit={handleOnSubmit}
          initialValues={values}
          validationSchema={validationSchemaUsuario}
          onChange={handleChange}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid
            /* and other goodies */
          }) => (
              <Container maxWidth="xl" className={classes.main}>
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Grid container
                    direction="row">
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      spacing={2}
                      alignItems="flex-start">
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="nombre"
                          label="Nombre Contacto"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.nombre}
                          error={touched.nombre && Boolean(errors.nombre)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          helperText="* Nombre obligatorio "
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="apellido_paterno"
                          label="Apellido Paterno"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.apellido_paterno}
                          error={touched.nombre && Boolean(errors.apellido_paterno)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          helperText="* Apellido Paterno obligatorio "
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="apellido_materno"
                          label="Apellido Materno"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.apellido_materno}
                          helperText={touched.apellido_materno ? errors.apellido_materno : ""}
                          error={touched.nombre && Boolean(errors.apellido_materno)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="cargo"
                          label="Cargo"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.cargo}
                          error={touched.nombre && Boolean(errors.cargo)}
                          margin="normal"
                          variant="outlined"
                          helperText="* Cargo obligatorio "
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Información de contacto.
                  </Typography>
                      <Divider />
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      spacing={2}
                      alignItems="center">
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="telefono"
                          label="Teléfono"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.textField}
                          value={values.telefono}
                          helperText={touched.telefono ? errors.telefono : ""}
                          error={touched.nombre && Boolean(errors.telefono)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="telefono_personal"
                          label="Teléfono personal"
                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.telefono_personal}
                          value={values.telefono_personal}
                          helperText={touched.telefono_personal ? errors.telefono_personal : ""}
                          error={touched.nombre && Boolean(errors.telefono_personal)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          name="email"
                          label="Email"

                          InputLabelProps={{
                            classes: {
                              root: classes.inputLabel,
                              shrink: "shrink"
                            }
                          }}
                          className={classes.email}
                          value={values.email}
                          helperText="* Email obligatorio "
                          error={touched.nombre && Boolean(errors.email)}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          multiline
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={handleCloseModal}>
                      Cancelar
                          </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={!isValid}>
                      Agregar Contacto
                    </Button>

                  </DialogActions>
                </form>
              </Container>
            )}
        </Formik>

      </DialogContent>
    </Dialog>


  );
}

export default FormularioContacto;
