import React, { Fragment, useEffect, useContext, useState } from "react";
import { CssBaseline, Container, makeStyles, Paper, Divider, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useEncuesta } from "../enviarEncuesta/hooks";




const logo = require('../../../img/logo.png');
const useStyles = makeStyles(theme => ({
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11)
  },
  img: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,

  },

}));

function ResultadoEncuestas(props) {

  const { id } = useParams();
  const [dataEncuestas, setDataEncuestas] = useState(null)

  const {
    encuesta
  } = useEncuesta(id)

  useEffect(() => {
    console.log("reusltado render", id)
    
    setDataEncuestas(encuesta)
    console.log(encuesta);
  }, [id])

  const classes = useStyles();
  return (

    <Fragment>
      <CssBaseline />
      <Container maxWidth="md" className={classes.main} >
        <Grid
          container
          style={{ marginTop: 20 }}
          direction="column"
          justify="center"
          alignItems="center">
          <Paper square={true} style={{ borderRadius: "15px", padding: 25 }}>
            <Typography variant="overline" display="block" gutterBottom align="center">Muchas gracias {encuesta?.persona_respuesta.nombre} por responder nuestra encuesta.</Typography>

            <img className={classes.img} src={logo} alt="logo" />
          </Paper>
        </Grid>
      </Container>
    </Fragment>

  );
}

export default ResultadoEncuestas;
