import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerContrato, actualizarContrato, obtenerGerencias, obtenerResponsables, obtenerSociedades, obtenerMandantes } from "../requestContrato";
import { useHistory, useParams } from "react-router-dom";
import { date, object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Contrato, SetContrato] = useState();
	const [Gerencias, SetGerencias] = useState();
	const [Responsables, SetResponsables] = useState();
	const [Sociedades, SetSociedades] = useState();
	const [Mandantes, SetMandantes] = useState();

	const { id: contratoID } = useParams();
	const history = useHistory();
	const notistack = useSnackbar();

	useEffect(() => {
		Promise.all([obtenerContrato(contratoID), obtenerGerencias(), obtenerResponsables(), obtenerSociedades(), obtenerMandantes()])
			.then(responses => {
				SetContrato(responses[0]);
				SetGerencias(responses[1]);
				SetResponsables(responses[2]);
				SetSociedades(responses[3]);
				SetMandantes(responses[4]);
			})
			.catch(error => {
				console.log(error);
				SetContrato({});
				SetGerencias([]);
				SetResponsables([]);
				SetSociedades([]);
				SetMandantes([]);
			});
	}, []);

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		_id: Contrato ? Contrato._id : "",
		nombre: Contrato ? Contrato.nombre : "",
		codigo: Contrato ? Contrato.codigo : "",
		codigo_nuevo: {
			gerencia: Contrato ? Contrato.codigo.split("-")[0] : "",
			responsable: Contrato ? Contrato.codigo.split("-")[1] : "",
			correlativo: Contrato ? Contrato.codigo.split("-")[2] : "",
		},
		estado: Contrato ? Contrato.estado : "",
		fecha_inicio: Contrato && Contrato.fecha_inicio ? Contrato.fecha_inicio : null,
		fecha_termino_estimada: Contrato && Contrato.fecha_termino_estimada ? Contrato.fecha_termino_estimada : null,
		fecha_termino_real: Contrato && Contrato.fecha_termino_real ? Contrato.fecha_termino_real : null,
		responsable_ref: Contrato && Contrato.responsable_ref ? Contrato.responsable_ref._id : "",
		gerencia_ref: Contrato && Contrato.gerencia_ref ? Contrato.gerencia_ref._id : "",
		mandante_ref: Contrato && Contrato.mandante_ref ? Contrato.mandante_ref._id : "",
		sociedad_ref: Contrato && Contrato.sociedad_ref ? Contrato.sociedad_ref._id : "",
	}

	/**
	 * Esquema de validación para el contrato.
	 */
	const validation = object().shape({
		nombre: string()
			.min(3, "El nombre debe tener al menos ${min} caracteres.")
			.max(50, "El nombre debe tener a lo más ${max} caracteres.")
			.required("El nombre es requerido."),
		codigo: string()
			.min(3, "El código debe tener al menos ${min} caracteres.")
			.max(15, "El código debe tener a lo más ${max} caracteres.")
			.required("El código es requerido."),
		estado: string()
			.required("El estado es requerido."),
		fecha_inicio: date()
			.nullable()
			.optional(),
		fecha_termino_estimada: date()
			.nullable()
			.required("La fecha estima de término es requerida."),
		fecha_termino_real: date()
			.nullable()
			.optional(),
		// .required("La fecha de término real es requerida."),
		responsable_ref: string()
			.required("El responsable es requerido."),
		gerencia_ref: string()
			.required("La gerencia es requerida."),
		mandante_ref: string()
			.required("El mandante es requerido."),
		sociedad_ref: string()
			.required("La sociedad es requerida."),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, helper) => handleActualizar(values),
	});

	useEffect(() => {
		let filtro = null;
		formik.values.responsable_ref = "";
		if (formik && formik.values && formik.values.gerencia_ref) {
			let gerenciaID = formik.values.gerencia_ref
			let gerencia = Array.from(Gerencias).find(g => g._id === gerenciaID);
			filtro = `gerencia=${gerencia.sigla}`;
		}
		obtenerResponsables(filtro)
			.then(responsables => {
				if (responsables) {
					SetResponsables(responsables);
				} else {
					SetResponsables([]);
				}
			})
			.catch(error => {
				console.error(error);
				SetResponsables([]);
			});
	}, [formik.values.gerencia_ref]);

	/**
	 * Handler para actualizar el contrato.
	 */
	const handleActualizar = (values) => {
		//Actualización del código del contrato.
		values.codigo = `${values.codigo_nuevo.gerencia}-${values.codigo_nuevo.responsable}-${values.codigo_nuevo.correlativo}`;
		//Se actualiza el contrato.
		actualizarContrato(values)
			.then(response => {
				console.log("Contrato actualizado exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Contrato actualizado exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar actualizar el contrato.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar actualizar el contrato.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/contratos");
			});
	}

	/**
	 * Handler para salir del formulario.
	 */
	const handleSalir = () => {
		history.push("/contratos");
	}

	return (
		<Page
			gerencias={Gerencias}
			responsables={Responsables}
			sociedades={Sociedades}
			mandantes={Mandantes}
			handle_salir={handleSalir}
			formik={formik}
		/>
	);
}

export default Index;