import Axios from "axios";

const BASE_URL = process.env.REACT_APP_DB_URL;

/**
 * Método encargado de obtener todas las sociedades.
 */
export async function obtenerSociedades() {
	try {
		let url = `${BASE_URL}/sociedades`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener una sociedad, por su ID.
 * @param {*} sociedadID ID de la sociedad.
 */
export async function obtenerSociedad(sociedadID) {
	try {
		let url = `${BASE_URL}/sociedades/${sociedadID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una sociedad.
 * @param {*} sociedad Datos de la sociedad.
 */
export async function agregarSociedad(sociedad) {
	try {
		let url = `${BASE_URL}/sociedades`;
		let response = await Axios.post(url, sociedad);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una sociedad.
 * @param {*} sociedad Datos actualizados de la sociedad.
 */
export async function actualizarSociedad(sociedad) {
	try {
		let url = `${BASE_URL}/sociedades/${sociedad._id}`;
		let response = await Axios.put(url, sociedad);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar una sociedad, por su ID.
 * @param {*} sociedadID ID de la sociedad.
 */
export async function eliminarSociedad(sociedadID) {
	try {
		let url = `${BASE_URL}/sociedades/${sociedadID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}