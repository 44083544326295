import React from "react";
import { Grid, Typography, CircularProgress, Chip, IconButton } from "@material-ui/core";
import { useContrato } from "../../../contratos/hooks";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import MomentJS from "moment";

function SubHeaderContratos(props) {

  const {
    idContrato,
  } = props;

  const {
    
    loading,
    contrato,

  } = useContrato(idContrato);


  const history = useHistory()

  const backPortafolio = () => {
    history.push("/")
  }


  return (

    <Grid
      container
      style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 36, paddingRight: 0 }}
      direction="row"
      justify="flex-start"
      alignItems="flex-start">
      {(loading) ?
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start">
          <Grid
            item>
            <CircularProgress />
          </Grid>
        </Grid>
        :
        <Grid
          container
          direction="column"
          justify="flex-start"
          spacing={1}
          alignItems="flex-start">
          <Grid
            item
            container
            
            direction="row"
            justify="flex-start"
            alignItems="flex-start">
            <Grid item>
              <IconButton aria-label="delete" onClick={backPortafolio} >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item style={{marginBottom: "auto",marginTop: "auto"}}>
              <Typography>
              {contrato.codigo} - {contrato.nombre}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid
            item
            md={5} xs={5}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                Fecha Estimada Término
              </Typography>
            </Grid>

          </Grid>
          <Grid
            item
            md={1} xs={1}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                :
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6} xs={6}
            item>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                {MomentJS(contrato.fecha_termino_estimada).year() !== 2100 ? MomentJS(contrato.fecha_termino_estimada).format("DD-MM-YYYY") : "indefinido"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid
            item
            md={5} xs={5}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                Gerencia
              </Typography>
            </Grid>

          </Grid>
          <Grid
            item
            md={1} xs={1}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                :
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6} xs={6}
            item>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                {contrato.gerencia_ref.nombre}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid
            item
            md={5} xs={5}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                Responsable
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            md={1} xs={1}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                :
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6} xs={6}
            item>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                {contrato.responsable.nombre} {contrato.responsable.apellido_paterno} {contrato.responsable.apellido_materno}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid
            item
            md={5} xs={5}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                Mandante
              </Typography>
            </Grid>

          </Grid>
          <Grid
            item
            md={1} xs={1}>
            <Grid item>
              <Typography variant="body2" display="block" gutterBottom>
                :
              </Typography>
            </Grid>
          </Grid>
          <Grid
            md={6} xs={6}
            item>
            <Grid item>
              <div  >
                <Chip label={contrato.mandante_ref.nombre} variant="outlined" color="primary" />
              </div>
            </Grid>
          </Grid>
        </Grid>

        </Grid>

      }
    </Grid >



  )

}

export default SubHeaderContratos;