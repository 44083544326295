import React, { Fragment } from "react";
import MaterialTable from "../../../components/material_table";
import { Paper } from "@material-ui/core";

function Page(props) {
	const {
		encuestas
	} = props;

	let columns = []

	return (
		<Fragment>
		
			<MaterialTable
				title="Encuestas"
				is_loading={!encuestas || Array.from(encuestas).length < 1}
				data={encuestas}
				columns={columns}
			/>
		</Fragment>

	);
}

export default Page;