import React, { createContext } from "react";


export const EncuestasContext = createContext();



// const reducer = (state, action) => {
//   switch (action.type) {
//     case "ADD_ENCUESTA":
//       return {
//         contacts: [...state.contacts, action.payload]
//       };
//     case "DEL_ENCUESTA":
//       return {
//         contacts: state.contacts.filter(
//           contact => contact.id !== action.payload
//         )
//       };
//     case "GET_ENCUESTA_BY_ID":
//       return {
//         contacts: state.contacts.filter(
//           contact => contact.id !== action.payload
//         )
//       };
//     case "GET_ALL_ENCUESTA":
//       return {
//         contacts: state.contacts.filter(
//           contact => contact.id !== action.payload
//         )
//       };
//     case "START":
//       return {
//         loading: true
//       };
//     case "COMPLETE":
//       return {
//         loading: false
//       };
//     default:
//       throw new Error();
//   }
// };


export const EncuestasContextProvider = props => {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const [infoEncuestasProvider, setInfoEncuetasProvider] = React.useState({});
  const {
    
    value
  } = props


  React.useEffect(() => {
    setInfoEncuetasProvider(value)
    return () => {
      console.log("cleaned up");
    };
  }, [value]);

  return (
    <EncuestasContext.Provider value={[infoEncuestasProvider, setInfoEncuetasProvider]}>
      {props.children}
    </EncuestasContext.Provider>
  );
};