import React, { useEffect, useState } from 'react';


import Page from './page'
import { useParams } from 'react-router-dom';

import { useMandanteWithIdContrato } from '../../mandantes/hooks';
import { EncuestasContextProvider } from '../context/encuestaContext';

import { ObtenerEncuestasPorContratoAndProcesarEstadistica } from '../../../services/database/encuestas';



function Home(props) {

    const { id } = useParams();
    const [contactos, setContactos] = useState([])
    const [infoEncuestas, setInfoEncuestas] = React.useState({});


    const {
        
        mandante } = useMandanteWithIdContrato(id);

    // const { infoEncuestas } = useEncuestas(id)


    useEffect(() => {
        if (mandante) {
            let contactosMerge = []
            mandante.responsables.map(contacto => {
                contactosMerge.push(contacto);
            });

            setContactos(contactosMerge)
        }
    }, [mandante])

    useEffect(() => {
        const loadEncuestas = async (id) => {
            let result  = await ObtenerEncuestasPorContratoAndProcesarEstadistica(id)
            setInfoEncuestas(result)
        }

        loadEncuestas(id)
    },[])



    return (
        <EncuestasContextProvider idContrato={id} value={infoEncuestas} >
            <Page contactos={contactos} idContrato={id} mandante={mandante} />
        </EncuestasContextProvider>

    );
}

export default Home;

