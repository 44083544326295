import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Moment from 'react-moment';
import MomentJS from "moment";
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';


import EstadisticasCyD from './component/EstadisticasCyD';



const style = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
	content: {
		flex: 1,
		padding: theme.spacing(3),
	},
	contenedor: {
		display: 'flex'
	},
	elemento: {
		width: '25%'
	},
	elemento10: {
		width: '10%'
	},
	padding16: {
		paddingLeft: '16',
		paddingRight: '16'
	},
	elementoContato: {
		width: '45%'
	},
	chip: {
		margin: theme.spacing(1),
	},
	main: {
		paddingLeft: theme.spacing(2),
		paddingTop: theme.spacing(2)
	}
}));

function getEncargadoView(responsable) {
	if (!isPromise(responsable)) {
		if (typeof responsable !== "undefined") {

			var urlFinal = responsable.persona.photo;


			return (
				<div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
					<Avatar alt="Remy Sharp" src={urlFinal} />
					<Box fontSize={13} m={1}>
						{responsable.persona.nombre_completo}
					</Box>
				</div>
			);
		} else {
			return (
				<div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
					<Box fontSize={13} m={1}>
						{"Sin Resposanble"}
					</Box>
				</div>
			);
		}
	} else {
		return (
			<div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
				<Avatar alt="Remy Sharp" src="" />
				<Box fontSize={13} m={1}>
				</Box>
			</div>
		);
	}
}

function generarFechaView(fecha_termino) {
	var fechaView = new Date(fecha_termino.fecha_termino_estimada);
	if (fechaView.getFullYear() === 2100) {
		return "indefinido";
	} else {
		return (
			<Moment format="DD/MM/YYYY">{fechaView}</Moment>
		);
	}
}

function isPromise(obj) {
	return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
}

function generarMandantesView(mandante) {
	if (!isPromise(mandante)) {
		if (typeof mandante.mandante_ref !== "undefined") {
			var nombreMandante = (mandante.mandante_ref.nombre.length < 25) ? mandante.mandante_ref.nombre : mandante.mandante_ref.nombre.substring(0, 25) + '...'
			return (
				<div style={{ margin: 10 }} >
					<Chip label={nombreMandante} variant="outlined" color="primary" />
				</div>
			);
		} else {
			return (
				<div style={{ margin: 10 }} >
					<Chip label={mandante} variant="outlined" color="primary" />
				</div>
			);
		}
	} else {
		return (
			<div style={{ margin: 10 }} >
				<Chip label={""} variant="outlined" color="primary" />
			</div>
		);
	}
}

function generarCodigoContratoView(codigo) {
	if (!isPromise(codigo)) {
		if (typeof codigo.codigo !== "undefined") {
			return (
				<Chip label={codigo.codigo} color="primary" />
			)
		} else {
			return (
				<Chip label={codigo} color="primary" />
			)
		}
	} else {
		return (
			<div style={{ margin: 10 }} >
				<Chip label={""} variant="outlined" color="primary" />
			</div>
		);
	}
}

function generarCodigoGerenciaView(gerencia) {
	if (!isPromise(gerencia)) {
		if (typeof gerencia.gerencia_ref?.sigla !== "undefined") {
			return (
				<Chip label={gerencia.gerencia_ref.sigla} variant="outlined" color="primary" />
			)
		} else {
			return (
				<Chip label={gerencia} variant="outlined" color="primary" />
			)
		}
	}
}

function TablaContratos(props) {
	const classes = style();
	const {
		data,
		nombre,
		loading
	} = props;
	const history = useHistory();




	var tituloTabla = "Portafolio de contratos ";
	return (
		<Fragment>
			<CssBaseline />
			<main>
				<Container maxWidth="xl" className={classes.main} >

					<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
						<Link color="inherit" href="/contratos">Módulo Contratos</Link>
						<Typography color="textPrimary">Encuestas {nombre}</Typography>
					</Breadcrumbs>
					<EstadisticasCyD />


					<MaterialTable
						title={tituloTabla}
						isLoading={loading}
						style={{ borderRadius: "15px" }}
						options={{
							filtering: true,
							pageSizeOptions: [20, 30, 40, 50, 60],
							pageSize: 20,
							grouping: true,
							exportButton: true,
							actionsColumnIndex: -1,
						}}
						localization={
							{
								header: {
									actions: "Encuestas",
								},
								body: {
									emptyDataSourceMessage: loading ? "Cargando" : "Sin registros",
								},
								toolbar: {
									addRemoveColumns: "Agregar o Quitar",
									searchPlaceholder: "Buscar",
									searchTooltip: "Buscar",
									showColumnsTitle: "Mostrar Columnas",
									exportTitle: "Exportar",
									exportName: "Exportar como CSV",
								},
								pagination: {
									labelDisplayedRows: "{from}-{to} de {count}",
									labelRowsSelect: "Filas",
									firstAriaLabel: "Primera Página",
									firstTooltip: "Primera Página",
									previousAriaLabel: "Página Anterior",
									previousTooltip: "Página Anterior",
									nextAriaLabel: "Siguiente Página",
									nextTooltip: "Siguiente Página",
									lastAriaLabel: "Última Página",
									lastTooltip: "Última Página",
								},
								grouping: {
									placeholder: "Arrastre los encabezados aquí para agrupar",
								}
							}
						}
						actions={[
							rowData => ({
								icon: 'email',
								iconProps: {
									style: { color: "#757575" }
								},
								tooltip: 'Enviar encuesta',
								onClick: (event, rowData) => {
									history.push("/envio_encuestas/" + rowData._id)
								},
							})
						]}

						columns={[
							{
								title: 'Código Proyecto',
								field: 'codigo',
								cellStyle: {
									fontSize: '0.9em',
									width: '15px',
								},
								render: rowData => generarCodigoContratoView(rowData)
							}, {
								title: 'Gerencia',
								field: 'gerencia_ref.sigla',
								render: rowData => generarCodigoGerenciaView(rowData),
								cellStyle: {
									width: '7px',
								},
							}, {
								title: 'Empresa',
								field: 'sociedad_ref.nombre',
								cellStyle: {
									// fontSize: '0.8em'
								},
							}, {
								title: 'Contrato',
								field: 'nombre',
								cellStyle: {
									//fontSize: '0.8em'
								},
							}, {
								title: 'Fecha Inicio',
								field: 'fecha_inicio',
								render: rowData => rowData?.fecha_inicio ? MomentJS(rowData.fecha_inicio).format("DD/MM/YYYY") : "Indefinida",
							}, {
								title: 'Fecha Est. Término',
								field: 'fecha_termino_estimada',
								render: rowData => generarFechaView(rowData),
								cellStyle: {
									// fontSize: '0.9em'
								},
								type: 'date'
							}, {
								title: 'Encargado',
								field: 'responsable.nombre_display',
								render: rowData => getEncargadoView(rowData.responsable_ref),
							}, {
								title: 'Mandante',
								field: 'mandante_ref.nombre',
								render: rowData => generarMandantesView(rowData)
							},
						]}
						data={data}
					/>
				</Container >
			</main>
		</Fragment >
	);
}


export default TablaContratos;