export const ACTION_OPEN_ADD_PERMISO = "ACTION_OPEN_ADD_PERMISO";
export const ACTION_OPEN_EDIT_PERMISO = "ACTION_OPEN_EDIT_PERMISO";
export const ACTION_OPEN_DELETE_PERMISO = "ACTION_OPEN_DELETE_PERMISO";
export const ACTION_REFRESH_TABLE_PERMISO = "ACTION_REFRESH_TABLE_PERMISO";

export const actionOpenAddPermiso = (openAddPermiso = false) => ({
  type: ACTION_OPEN_ADD_PERMISO,
  openAddPermiso: openAddPermiso
});
export const actionOpenEditPermiso = (openEditPermiso = false, permisoSeleccionado = null) => ({
  type: ACTION_OPEN_EDIT_PERMISO,
  openEditPermiso: openEditPermiso,
  permisoSeleccionado: permisoSeleccionado
});
export const actionOpenDeletePermiso = (openDeletePermiso = false, permisoSeleccionado = null) => ({
  type: ACTION_OPEN_DELETE_PERMISO,
  openDeletePermiso: openDeletePermiso,
  permisoSeleccionado: permisoSeleccionado

});
export const actionRefreshTablePermiso = (refreshTablePermiso = false) => ({
  type: ACTION_REFRESH_TABLE_PERMISO,
  refreshTablePermiso: refreshTablePermiso
});