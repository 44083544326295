/**
 * Funciones de consulta a base de datos para roles
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import axios from 'axios';

/**
 * Obtener un rol de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getRol = (id, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/${id}`;
  const promise = axios.get(url, {
    canceltoken: cancelToken
  });
  return promise
}

/**
 * Obtener todos los roles de la base de datos
 * @param {Object} filters filtros para aplicar a los resultados de la búsqueda
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getAllRol = (filters, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/`;
  const promise = axios.get(url, {
    params: filters,
    canceltoken: cancelToken
  });
  return promise;
}

/**
 * Actualizar los datos un rol en la base de datos
 * @param {String} id id del registro
 * @param {Object} data campos actualizados
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const updateRol = (id, data, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/${id}`;
  const promise = axios.put(url, data, {
    canceltoken: cancelToken
  });
  return promise;
}

/**
 * Eliminar un rol de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deleteRol = (id, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/${id}`;
  const promise = axios.delete(url, {
    canceltoken: cancelToken
  });
  return promise;
}

/**
 * Crear un nuevo rol en la base de datos
 * @param {Object} data Campos del nuevo registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postRol = (data, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/`;
  const promise = axios.post(url, data, {
    canceltoken: cancelToken
  });
  return promise
}

/**
 * Obtener los perfiles de un rol de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getPerfilesRol = (id, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/${id}/perfiles`;
  const promise = axios.get(url, {
    canceltoken: cancelToken
  });
  return promise
}

/**
 * Agregar los perfiles de a un rol de la base de datos
 * @param {String} id id del registro
 * @param {Array} perfiles listado de id de perfiles
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postPerfilesRol = (id, perfiles, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/${id}/perfiles`;
  const promise = axios.post(url, perfiles, {
    canceltoken: cancelToken
  });
  return promise
}


/**
 * Eliminar los perfiles de a un rol de la base de datos
 * @param {String} id id del registro
 * @param {Array} perfiles listado de id de perfiles
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deletePerfilesRol = (id, perfiles, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/roles/${id}/perfiles`;
  const promise = axios.delete(url, {
    canceltoken: cancelToken,
    data: perfiles
  });
  return promise
}
