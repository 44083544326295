import React, { useContext, useEffect } from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import MomentJS from "moment";
import { EncuestasContext } from "../../context/encuestaContext";



export default function TablaEncuestas(props) {

	// const {
	// 	infoEncuestas
	// } = props


	const [infoEncuestas, setInfoEncuetas] = useContext(EncuestasContext);
	const [data, setData] = React.useState([])

	const GlobalCss = withStyles({
		// @global is handled by jss-plugin-global.
		'@global': {
			// You should target [class*="MuiButton-root"] instead if you nest themes.
			'.MuiTableSortLabel': {
				active: {
					color: '#fff',
				},
				root: {
					"&:focus": {
						color: "#fff"
					},
					"&:hover": {
						color: "#fff"
					}
				}
			},
		},
	})(() => null);


	let localization = {
		toolbar: {
			searchPlaceholder: "Buscar",
			searchTooltip: "Buscar",
			exportTitle: "Exportar",
			exportName: "Descargar Datos",
			showColumnsTitle: "Mostrar Columnas",
			addRemoveColumns: "Agregar o Quitar",
		},
		header: {
			actions: "Opciones"
		},
		body: {
			emptyDataSourceMessage: "Cargando"
		},
		pagination: { labelRowsSelect: "Filas" }
	}

	useEffect(() => {
		console.log("reload encuestas")
		console.log(infoEncuestas)
		
		setData(infoEncuestas?.encuestas)
	}, [infoEncuestas])


	return (
		<Paper elevation={1} >
			<GlobalCss />
			<MaterialTable
				classes={{

				}}
				localization={localization}
				columns={[
					{
						title: 'Código',
						field: 'codigo',
						defaultSort: 'desc',
						cellStyle: {
							width: 100,
							minWidth: 100
						},
					},
					{
						title: 'Estado',
						field: 'estado',
						headerStyle: {
							"&:focus": {
								color: "#fff"
							},
							"&:hover": {
								color: "#fff"
							},
							color: "#fff"
						},
						cellStyle: {
							width: 10,
							minWidth: 10,
						}
					},
					{
						title: 'Enviado por ',
						field: 'personas_envio[0].nombre',
						cellStyle: {
							width: 100,
							minWidth: 100
						},
						render: (rowData) => {
							if (rowData.persona_respuesta) {
								return rowData.persona_respuesta.nombre + " " + rowData.persona_respuesta.apellido_paterno ?? "--"
							} else {
								return rowData.persona_respuesta_email;
							}

						}
					
					},
					{
						title: 'Para ',
						field: 'persona_respuesta.nombre',
						cellStyle: {
							width: 100,
							minWidth: 100
						},
						render: (rowData) => {
							if (rowData.persona_respuesta) {
								return rowData.persona_respuesta.nombre + " " + rowData.persona_respuesta.apellido_paterno ?? "--"
							} else {
								return rowData.persona_respuesta_email;
							}

						}
					},
					{
						title: 'Fecha de envío ',
						field: 'fecha_envio',
						headerStyle: {
							"&:focus": {
								color: "#fff"
							},
							"&:hover": {
								color: "#fff"
							},
							color: "#fff"
						},
						cellStyle: {
							width: 10,
							minWidth: 10,


						},
						type: "datetime",

						render: (rowData) => {
							
							return MomentJS(rowData.fecha_envio).format("DD-MM-YYYY hh:mm") ?? "--"
						}
					},

					{
						title: 'Fecha de Respuesta  ',
						field: 'fecha_actualizacion',
						cellStyle: {
							width: 100,
							minWidth: 100
						},
						render: (rowData) => {
							if (rowData.estado === "Terminada") {
								return MomentJS(rowData.fecha_actualizacion).format("DD-MM-YYYY HH:mm") ?? "--"
							} else {
								return "--"
							}
						}
					},
					{
						title: 'Calificación',
						field: 'resultado_respuesta',
						cellStyle: {
							width: 10,
							minWidth: 10
						},
						render: (rowData) => {
							if (rowData.estado === "Terminada") {
								return rowData.resultado_respuesta ?? "--"
							} else {
								return "--"
							}
						}
					},
					{
						title: 'Comentario',
						field: 'comentario_respuesta',
						headerStyle: {
							"&:focus": {
								color: "#fff"
							},
							"&:hover": {
								color: "#fff"
							},
							color: "#fff"
						},
						cellStyle: {
							width: 100,
							minWidth: 100
						},
						render: (rowData) => {
							let valor;
							if(rowData.comentario_respuesta){
								valor  = truncate(rowData.comentario_respuesta)
							}else{
								valor = "";
							}
							
							return valor
						}
						
					},



				]}
				options={{
					search: false,
					paging: false,
					showTitle: false,
					padding: "dense",
					toolbar: false,
					headerStyle: {
						backgroundColor: '#731f1f',
						color: '#FFF'
					}
				}}
				data={data}
				export={false}

			/>
		</Paper>
	);

	function truncate(input) {
		if (input.length > 10) {
			 return input.substring(0, 100) + '...';
		}
		return input;
 };
}
