import React, { useEffect, useContext } from "react";
import { ObtenerGerencias } from '../../../services/database/gerencias'
import 'moment/locale/es';


export const useGerencias = () => {
  const [gerencias, setGerencias] = React.useState(null)
  const [errorGerencias, setErrorGerencias] = React.useState(false)
  const [loadGerencias, setLoadGerencias] = React.useState(true)


  useEffect(() => {
    ObtenerGerencias().then((result) => {
      
      
      
      setGerencias(result)
      setLoadGerencias(false)
    }).catch(error => {
      setErrorGerencias(error);
    })

  }, [])

  return {
    gerencias,
    errorGerencias,
    loadGerencias
  }
}