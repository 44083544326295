import React, { useState, useEffect } from "react";
import Page from "./page";
import { ObtenerEncuestas } from "../../../services/database/encuestas";
import { useSelector } from "react-redux";

function Index(props) {
	const [Encuestas, SetEncuestas] = useState([]);
	const { usuarioSesion } = useSelector(state => ({
		usuarioSesion: state.usuarioSesion
	}));

	useEffect(() => {
		ObtenerEncuestas(usuarioSesion)
			.then(encuestas => SetEncuestas(encuestas))
			.catch(error => SetEncuestas([]));
	}, []);

	return (
		<Page
			encuestas={Encuestas}
		/>
	);
}

export default Index;