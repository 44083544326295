import React, { useEffect, useState } from "react";
import Page from "./page";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getPermisosPerfil, deletePermisosPerfil } from "../requestPerfiles";
import { actionOpenAddPermisoPerfil, actionOpenViewPermisoPerfil, actionRefreshTablePermisosPerfil } from "../actionsPerfiles";
// import { actionOpenAddPerfil, actionRefreshTablePerfil, actionOpenEditPerfil, actionOpenDeletePerfil } from "../actionsPerfiles";
export default function ListadoPermisosPerfil() {

  const { open, perfil, resfresh } = useSelector((state) => ({
    open: state.reducerPerfil.openViewPermisoPerfil,
    perfil: state.reducerPerfil.perfilSeleccionado,
    resfresh: state.reducerPerfil.refreshTablePermisosPerfil
  }));
  const notistack = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onEntered = () => {
    setIsLoading(true);
    setData([]);
    getPermisosPerfil(perfil._id)
      .then((response) => {
        console.log(response);
        // dispatch(actionRefreshTablePerfil(false));
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data);
        } else if (response.status === 204) {
          setIsLoading(false);
          setData([]);
        } else {
          setIsLoading(false);
          setData([]);
        }
        dispatch(actionRefreshTablePermisosPerfil(false));
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setData([]);
      });
  }

  const onClose = () => dispatch(actionOpenViewPermisoPerfil());

  const onClickOpenAddPermisoPerfil = () => dispatch(actionOpenAddPermisoPerfil(true));
  const onClickDeletePermisoPerfil = (rowData) => {
    try {
      deletePermisosPerfil(perfil._id, [rowData._id])
        .then((response) => {
          if (response.status === 204) {
            const successkey = notistack.enqueueSnackbar("Permiso Eliminado", {
              variant: 'success',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });
            onEntered();
          } else {
            console.log(response);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el permiso. Intente nuevamente y contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          }
        })
        .catch((e) => {
          console.log(e);
          const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el permiso. Contacte con soporte el equipo de soporte", {
            variant: 'error',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
          });
        });

    }
    catch (e) {
      console.log(e);
      const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el permiso. Contacte con soporte el equipo de soporte", {
        variant: 'error',
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
      });
    }
  };
  // const onClickOpenDeletePerfil = (rowData) => dispatch(actionOpenDeletePerfil(true, rowData));
  // const onClickOpenEditPerfil = (rowData) => dispatch(actionOpenEditPerfil(true, rowData));

  useEffect(() => {
    if (resfresh && perfil) onEntered();
  }, [resfresh, perfil]);
  return (
    <Page
      data={data}
      isLoading={isLoading}
      onClose={onClose}
      onEntered={onEntered}
      open={open}
      onClickOpenAddPermisoPerfil={onClickOpenAddPermisoPerfil}
      onClickDeletePermisoPerfil={onClickDeletePermisoPerfil}
    />
  );
}