import React, { useState, useEffect } from "react";
import Page from "./page";
import { obtenerSociedades } from "../requestSociedad";
import XLSX from "xlsx";

function Index(props) {
	const [Sociedades, SetSociedades] = useState();

	useEffect(() => {
		obtenerSociedades()
			.then(sociedades => SetSociedades(sociedades))
			.catch(error => SetSociedades([]));
	}, []);

	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Sociedades.map(s => ({
			codigo: s.codigo,
			rut: s.rut_formateado,
			nombre: s.nombre,
			giro: s.giro,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Sociedades");
		XLSX.writeFile(wb, "sociedades.xlsx");
	}

	return (
		<Page
			sociedades={Sociedades}
			handle_export={handleExport}
		/>
	);
}

export default Index;