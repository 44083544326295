import React, { useContext, useEffect } from "react";
import MaterialTable from "material-table";
import 'moment/locale/es';
import MomentJS from "moment";



export default function TablaResumenPorMes(props) {

  const {
    data
  } = props

  useEffect(() => {
    const arrayDeMeses = MomentJS.months()
    arrayDeMeses.forEach(() => {

    })
  }, [])

  const data2 = [
    {
      mes: "enero",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "febrero",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "marzo",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "abril",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "mayo",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "junio",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "julio",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "agosto",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "septiembre",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "octubre",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "noviembre",
      2020: "30%",
      2021: "40%"
    },
    {
      mes: "diciembre",
      2020: "30%",
      2021: "40%"
    },

  ]


  let localization = {
    toolbar: {
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
      exportTitle: "Exportar",
      exportName: "Descargar Datos",
      showColumnsTitle: "Mostrar Columnas",
      addRemoveColumns: "Agregar o Quitar",
    },
    header: {
      actions: "Opciones"
    },
    body: {
      emptyDataSourceMessage: "Cargando"
    },
    pagination: { labelRowsSelect: "Filas" }
  }

  return (
    <MaterialTable
      classes={{

      }}
      localization={localization}
      columns={[
        {
          title: 'Mes',
          field: 'mes',
          sorting: false,
          cellStyle: {
            width: 100,
            minWidth: 100
          },
        },
        {
          title: '2020',
          field: '2020',
          sorting: false,
          headerStyle: {
            "&:focus": {
              color: "#fff"
            },
            "&:hover": {
              color: "#fff"
            },
            color: "#fff"
          },
          cellStyle: {
            width: 10,
            minWidth: 10,
          }
        },
        {
          title: '2021',
          field: '2021',
          sorting: false,
          headerStyle: {
            "&:focus": {
              color: "#fff"
            },
            "&:hover": {
              color: "#fff"
            },
            color: "#fff"
          },
          cellStyle: {
            width: 10,
            minWidth: 10,
          }
        },
        
      ]}
      options={{
        search: false,
        paging: false,
        showTitle: false,
        padding: "dense",
        toolbar: false,
        headerStyle: {
          backgroundColor: '#731f1f',
          color: '#FFF'
        }
      }}
      data={data}
      export={false}
    />
  )
}

