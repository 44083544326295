import React, { useState, useEffect } from "react";
import Page from "./page";
import { obtenerGerencias } from "../requestGerencia";
import XLSX from "xlsx";

function Index(props) {
	const [Gerencias, SetGerencias] = useState();

	useEffect(() => {
		obtenerGerencias()
			.then(gerencias => SetGerencias(gerencias))
			.catch(error => {
				console.error(error);
				SetGerencias([]);
			});
	}, []);

	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Gerencias.map(g => ({
			nombre: g.nombre,
			gerencia: g.sigla,
			encargado: g.responsable_ref.persona.nombre_completo,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Gerencias");
		XLSX.writeFile(wb, "gerencias.xlsx");
	}

	return (
		<Page
			gerencias={Gerencias}
			handle_export={handleExport}
		/>
	);
}

export default Index;