export const ACCESS_ERROR = "/accessError";
export const LANDING = "/";
export const LOGIN = "/login";
export const REDIRECT = "/redireccionar";

export const HOME = "/home"

export const CONTRATOS = "/contratos"
export const CONTRATO = "/contrato"
export const VER_CONTRATO = "/contrato/:id"

export const MANDANTES = "/mandantes"
export const MANDANTE = "/mandante"
export const VER_MANDANTE = "/mandante/:id"

export const SOCIEDADES = "/sociedades"
export const SOCIEDAD = "/sociedad"
export const VER_SOCIEDAD = "/sociedad/:id"

export const GERENCIAS = "/gerencias"
export const GERENCIA = "/gerencia"
export const VER_GERENCIA = "/gerencia/:id"

export const RESPONSABLES = "/responsables";
export const RESPONSABLE = "/responsable";
export const VER_RESPONSABLE = "/responsable/:id";

export const ENCUESTAS = "/encuestas"
export const ENCUESTA = "/encuesta"
export const VER_ENCUESTA = "/encuesta/:id"
export const VER_ENCUESTA_CONTRATOS = "/encuesta/contratos"
export const ENVIO_ENCUESTA_PROYECTO = "/envio_encuestas/:id"
export const RESULTADO_ENCUESTAS = "/resultado_encuesta/:id"

export const CONFIGURACION = "/configuracion"