import React from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { deletePerfil } from '../requestPerfiles';
import { actionOpenDeletePerfil, actionRefreshTablePerfil } from '../actionsPerfiles';
/**
 * Lógica de componente para eliminar perfil
 * @author Luis San Martín S.
 */

export default function EliminarPerfil(props) {

  const dispatch = useDispatch();
  const notistack = useSnackbar();
  const { open, perfil} = useSelector(state => ({
    open: state.reducerPerfil.openDeletePerfil,
    perfil: state.reducerPerfil.perfilSeleccionado
  }));
  /**
   * Cargar datos del formulario
   */
  const handleOnSubmit = async () => {

    
    if (perfil) {
      const key = notistack.enqueueSnackbar("Eliminado Perfil...", {
        persist: true,
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        }
      });
      deletePerfil(perfil._id)
        .then((response) => {
          if (response.status === 204) {

            dispatch(actionRefreshTablePerfil(true));
            dispatch(actionOpenDeletePerfil());
            notistack.closeSnackbar(key);
            const successkey = notistack.enqueueSnackbar("Operanción exitosa: Perfil eliminado", {
              variant: 'success',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(successkey)}><Close /></IconButton>
            });
          } else {
            console.log("error", response);
            notistack.closeSnackbar(key);
            const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el Perfil. Contacte con soporte el equipo de soporte", {
              variant: 'error',
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom"
              },
              action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          const errorkey = notistack.enqueueSnackbar("Error: No ha sido posible eliminar el Perfil. Contacte con soporte el equipo de soporte", {
            variant: 'error',
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom"
            },
            action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
          });
        });
    }


  }
  /**
   * Cerrar dialogo
   */
  const handleClose = async () => dispatch(actionOpenDeletePerfil());
  


  return (


    <Page
      open={open}
      handleClose={handleClose}
      handleOnSubmit={handleOnSubmit}
    />
  );
}