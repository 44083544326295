/**
 * Funciones de consulta a base de datos para permisos
 * @since 1.2.0
 * @author Luis San Martín S.
 */

import axios from 'axios';

/**
 * Obtener un permiso de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getPermiso = (id, cancelToken=null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/permisos/${id}`;
  const promise = axios.get(url, {
      canceltoken: cancelToken
  });
  return promise
}

/**
 * Obtener todos los permisos de la base de datos
 * @param {Object} filters filtros para aplicar a los resultados de la búsqueda
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const getAllPermiso = (filters, cancelToken = null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/permisos/`;
  const promise = axios.get(url, {
      params: filters,
      canceltoken: cancelToken
  });
  return promise;
}

/**
 * Actualizar los datos un permiso en la base de datos
 * @param {String} id id del registro
 * @param {Object} data campos actualizados
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const updatePermiso = (id, data, cancelToken=null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/permisos/${id}`;
  const promise = axios.put(url, data, {
      canceltoken: cancelToken
  });
  return promise;
}

/**
 * Eliminar un permiso de la base de datos
 * @param {String} id id del registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const deletePermiso = (id, cancelToken=null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/permisos/${id}`;
  const promise = axios.delete(url, {
      canceltoken: cancelToken
  });
  return promise;
}

/**
 * Crear un nuevo permiso en la base de datos
 * @param {Object} data Campos del nuevo registro
 * @param {function} [cancelToken=null] cancelacion de request
 * @return {Promise} promesa de consulta
 */
export const postPermiso = (data, cancelToken=null) => {
  const base = process.env.REACT_APP_DB_URL;
  const url = `${base}/permisos/`;
  const promise = axios.post(url, data, {
      canceltoken: cancelToken
  });
  return promise
}