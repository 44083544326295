import React, { useState, useEffect } from 'react';
import { LineChart, ResponsiveContainer, BarChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';

import { Paper, Grid, Typography, Divider, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { useEncuestasFilter } from '../../../encuestas/enviarEncuesta/hooks';
import 'moment/locale/es';
import MomentJS from "moment";

import FiltroEstadistico from './FiltroEstadistico'
import TablaResumenPorMes from './TablaResumenPorMes';
import Indicadores from './Indicadores';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';





const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    borderRadius: "15px",

    backgroundColor: "rgb(255,255,255,0.5)"
  },
}));



function EstadisticasCyD(props) {
  const classes = useStyles();



  const [graficoBar, setGraficoBar] = useState([])
  const [tablaDatos, setTablaDatos] = useState([])
  const [gerenciaFilter, setGerenciaFilter] = useState(null);
  const [clienteFilter, setClienteFilter] = useState(null);
  const [responsableFilter, setResponsableFilter] = useState(null);
  const [contratoFilte, setContratoFilter] = useState(null)

  const [clearFiltro, setClearFiltro] = useState(false)

  const notistack = useSnackbar();

  const { encuestas, loadProcesaEncuestas, errorEncuesta } = useEncuestasFilter(gerenciaFilter, clienteFilter, responsableFilter, contratoFilte);


  const procesarDatosParaTabla = () => {
    const arrayDeMeses = MomentJS.monthsShort()
    const arrayDeMesesLong = MomentJS.months()

    let currentYear = MomentJS();
    let graficoBarAux = encuestas.encuestaPorMes.filter(enc => {
      return enc.ano === currentYear.format('YYYY')
    })
    let graficoBarAuxDos = encuestas.encuestaPorMes.filter(enc => {
      return enc.ano === currentYear.add(1, 'years')
    })

    let resultPorAno = arrayDeMeses.map((item, index) => {

      return {
        mes: arrayDeMesesLong[index],
        2020: (graficoBarAux[index]?.nps) ? graficoBarAux[index]?.nps + ' %' : '--',
        2021: (graficoBarAuxDos[index]?.nps) ? graficoBarAuxDos[index]?.nps + ' %' : '--',
      }

    })
    return {
      resultPorAno,
      graficoBarAux
    }
  }

  useEffect(() => {
    if (encuestas) {
      let dataProcesada = procesarDatosParaTabla();
      setTablaDatos(dataProcesada.resultPorAno)
      setGraficoBar(dataProcesada.graficoBarAux)
    }
  }, [encuestas])

  useEffect(() => {
    setClienteFilter(null)
    setResponsableFilter(null)
    setContratoFilter(null)
    setClearFiltro(true);
  }, [errorEncuesta])

  const handleChangeFilter = (filtro) => {
    setGerenciaFilter(filtro.gerenciaFilter !== "" ? filtro.gerenciaFilter : null)
    setClienteFilter(filtro.clienteFilter !== "" ? filtro.clienteFilter : null)
    setResponsableFilter(filtro.responsableFilter !== "" ? filtro.responsableFilter : null)
    setContratoFilter(filtro.contratoFilter !== "" ? filtro.contratoFilter : null)
  }


  return (
    <div style={{
      transform: 'translateZ(0px)',
      flexGrow: 1
    }}>
      <Paper style={{ marginBottom: 13, paddingBottom: 30, borderRadius: "15px" }} >
        <Backdrop open={loadProcesaEncuestas} className={classes.backdrop} >
          <CircularProgress />
        </Backdrop>
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 10 }}
          alignItems="stretch">
          <Typography variant="h6" gutterBottom>Estadisticas CyD</Typography>
        </Grid>
        <Divider variant="middle" />
        <FiltroEstadistico gerencia="" onChangefilter={handleChangeFilter} clearFiltro={clearFiltro} setClearFiltro={setClearFiltro} />
        <Divider variant="middle" />
        <Grid>
          <Indicadores data={encuestas?.resumen} />
        </Grid>
        <Divider variant="middle" />
        <Grid
          item
          container
          style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="flex-start">
          <Grid item md={3} style={{ width: '100%', }}>
            <TablaResumenPorMes data={tablaDatos} />
          </Grid>
          <Grid item md={5} style={{ width: '100%', height: 420 }}>
            <Typography variant="subtitle1" gutterBottom align="center">NPS Mensual (%)</Typography>
            <ResponsiveContainer >
              <LineChart data={graficoBar}
                margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes" />
                <YAxis domain={[-100, 100]} />
                <ReferenceLine y={0} stroke='#000' />
                <Tooltip />
                {/* <Tooltip content={<CustomTooltip />} /> */}
                <Legend />
                <Line type="monotone" dataKey="nps" name="NPS" stroke="#a64c46" />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item md={4} style={{ width: '100%', height: 420 }}>
            <Typography variant="subtitle1" gutterBottom align="center">% de respuestas de encuestas a cliente</Typography>
            <ResponsiveContainer >
              <BarChart data={graficoBar}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Detractores" stackId="a" da fill="#c0392b" />
                <Bar dataKey="Neutros" stackId="a" fill="#7f8c8d" />
                <Bar dataKey="Promotores" stackId="a" fill="#27ae60" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>

    </div >
  )
}

export default EstadisticasCyD;