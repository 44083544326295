export const ACTION_OPEN_ADD_PERFIL_ROL = "ACTION_OPEN_ADD_PERFIL_ROL";
export const ACTION_OPEN_VIEW_PERFIL_ROL = "ACTION_OPEN_VIEW_PERFIL_ROL";
export const ACTION_REFRESH_TABLE_ROL = "ACTION_REFRESH_TABLE_PERFIL";
export const ACTION_REFRESH_TABLE_PERFILES_ROL = "ACTION_REFRESH_TABLE_PERFILES_ROL";

export const actionOpenAddPerfilRol = (openAddPerfilRol = false) => ({
  type: ACTION_OPEN_ADD_PERFIL_ROL,
  openAddPerfilRol: openAddPerfilRol
});
export const actionOpenViewPerfilRol = (openViewPerfilRol = false, rolSeleccionado) => ({
  type: ACTION_OPEN_VIEW_PERFIL_ROL,
  openViewPerfilRol: openViewPerfilRol,
  rolSeleccionado: rolSeleccionado
});
export const actionRefreshTableRol = (refreshTableRol = false) => ({
  type: ACTION_REFRESH_TABLE_ROL,
  refreshTableRol: refreshTableRol
});
export const actionRefreshTablePerfilesRol = (refreshTablePerfilesRol = false) => ({
  type: ACTION_REFRESH_TABLE_PERFILES_ROL,
  refreshTablePerfilesRol: refreshTablePerfilesRol
});