import React, { useEffect } from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Select, TextField, Chip } from '@material-ui/core';
import { useGerencias } from '../../../gerencias/hooks'
import { useContratosFilter } from '../../../contratos/hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
function FiltroEstadistico(props) {

  const {
    onChangefilter,
    clearFiltro,
    setClearFiltro
  } = props;




  const [gerenciaFilter, setGerenciaFilter] = React.useState("all_gerencias")

  const [clienteFilter, setClienteFilter] = React.useState(null)
  const [clienteFilterInput, setClienteFilterInput] = React.useState("")

  const [responsableFilter, setResponsableFilter] = React.useState(null)
  const [responsableFilterInput, setResponsableFilterInput] = React.useState("")

  const [contratoFilter, setContratoFilter] = React.useState(null)
  const [contratoFilterInput, setContratoFilterInput] = React.useState("")

  const [desdeFilter, setDesdeFilter] = React.useState('')
  const [hastaFilter, setHastaFilter] = React.useState('')

  const [responsables, setResponsables] = React.useState([])
  const [clientes, setClientes] = React.useState([])

  const {
    gerencias
  } = useGerencias();

  const {
    contratos

  } = useContratosFilter(gerenciaFilter, responsableFilter)

  const [contratosList, setContratosList] = React.useState(contratos)

  const handleChangeGerencia = (event) => {
    setGerenciaFilter(event.target.value);
  };
  const handleChangeCliente = (event, newValue) => {
    if (newValue) {
      setClienteFilter(newValue._id);
    } else {
      setClienteFilter(null);
    }

  };
  const handleChangeResponsable = (event, newValue) => {


    if (newValue) {
      setResponsableFilter(newValue.run);
    } else {

      setResponsableFilter("");
    }



  };
  const handleChangeContrato = (event, newValue) => {
    if (newValue) {
      setContratoFilter(newValue._id);
    } else {
      setContratoFilter("");
    }

  };
  const callEventUpdateDatos = () => {
    onChangefilter({
      gerenciaFilter: gerenciaFilter,
      clienteFilter: clienteFilter,
      responsableFilter: responsableFilter,
      contratoFilter: contratoFilter,
      desdeFilter: desdeFilter,
      hastaFilter: hastaFilter
    })
  }
  const clearAllfiltros = () => {

    setClienteFilter(null)
    setResponsableFilter(null)
    setContratoFilter("")
    // setDesdeFilter('')
    // setHastaFilter('')
  }

  const loadContratosClienteGerenciaFiltroSegunFiltro = (gerencia, cliente, responsable) => {
    let contratosFiltrado = []
    let clientesFiltrado = []
    let responsablesFiltrado = []

    if (gerencia !== "all_gerencias" && cliente === null && responsable === null) {
      contratosFiltrado = contratos.filter(con => con.gerencia_ref._id === gerencia)
    } else if (gerencia !== "all_gerencias" && cliente !== null && responsable === null) {
      contratosFiltrado = contratos.filter(con => con.gerencia_ref._id === gerencia).filter(con => con.mandante_ref._id === cliente._id)
    } else if (gerencia === "all_gerencias" && cliente !== null && responsable === null) {
      contratosFiltrado = contratos.filter(con => con.mandante_ref._id === cliente._id)
    } else if (gerencia === "all_gerencias" && cliente !== null && responsable !== null) {
      contratosFiltrado = contratos.filter(con => con.mandante_ref._id === cliente._id).filter(con => con.responsable.run === responsable.run)
    } else if (gerencia !== "all_gerencias" && cliente === null && responsable !== null) {
      contratosFiltrado = contratos.filter(con => con.gerencia_ref._id === gerencia).filter(con => con.responsable.run === responsable.run)
    } else if (gerencia !== "all_gerencias" && cliente !== null && responsable !== null) {
      contratosFiltrado = contratos.filter(con => con.gerencia_ref._id === gerencia).filter(con => con.mandante_ref._id === cliente._id).filter(con => con.responsable.run === responsable.run)
    } else if (gerencia === "all_gerencias" && cliente === null && responsable !== null) {
      contratosFiltrado = contratos.filter(con => con.responsable.run === responsable.run)
    } else {
      contratosFiltrado = contratos;
    }
    contratosFiltrado.map(contratoRef => {
      if (responsablesFiltrado.length !== 0) {
        let responsable = responsablesFiltrado.find(responsable => responsable.run === contratoRef.responsable.run);
        if (!responsable) {
          responsablesFiltrado.push(contratoRef.responsable)
        }
      } else {
        responsablesFiltrado.push(contratoRef.responsable)
      }
      if (clientesFiltrado.length !== 0) {
        let clienteSearch = clientesFiltrado.find(cliente => cliente._id === contratoRef.mandante_ref._id);
        if (!clienteSearch) {
          clientesFiltrado.push(contratoRef.mandante_ref)
        }
      } else {
        clientesFiltrado.push(contratoRef.mandante_ref)
      }
    });

    return {
      contratosFiltrado: contratosFiltrado,
      clientesFiltrado: clientesFiltrado,
      responsablesFiltrado: responsablesFiltrado
    }
  }

  // useEffect(() => {
  //   if (clearFiltro) {
  //     clearAllfiltros()
  //     setClearFiltro(false)
  //   }
  // }, [clearFiltro])

  useEffect(() => {
    const { contratosFiltrado, clientesFiltrado, responsablesFiltrado } = loadContratosClienteGerenciaFiltroSegunFiltro(gerenciaFilter, clienteFilter, responsableFilter)
    setContratosList(contratosFiltrado)
    setClientes(clientesFiltrado)
    setResponsables(responsablesFiltrado)
    callEventUpdateDatos()
  }, [contratos])

  useEffect(() => {
    callEventUpdateDatos()
  }, [contratoFilter])

  useEffect(() => {
    const { contratosFiltrado, clientesFiltrado, responsablesFiltrado } = loadContratosClienteGerenciaFiltroSegunFiltro(gerenciaFilter, clienteFilter, responsableFilter)
    setContratosList(contratosFiltrado)
    setClientes(clientesFiltrado)
    setResponsables(responsablesFiltrado)
    callEventUpdateDatos()
  }, [clienteFilter])

  useEffect(() => {
    clearAllfiltros();
    callEventUpdateDatos()
  }, [gerenciaFilter])


  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justify="flex-start"
      style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 10 }}
      alignItems="stretch">
      <Grid item md={2} xs={12}>
        <FormControl variant="filled" margin="dense" size="small" fullWidth>
          <InputLabel id="demo-simple-select-filled-label" >Gerencia</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            onChange={handleChangeGerencia}
            value={gerenciaFilter}>
            <MenuItem key="0" value="all_gerencias">
              <em>Todo CyD </em>
            </MenuItem>
            {
              gerencias?.map((o, i) => {
                return <MenuItem key={o?._id} value={o?._id}>{o?.sigla} - {o?.nombre}</MenuItem>
              })
            }

          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12}>
        <Autocomplete
          style={{ paddingTop: 8 }}
          id="filter-cliente"
          size="small"
          value={clienteFilter}
          onChange={(event, newValue) => {
            setClienteFilter(newValue);
          }}
          inputValue={clienteFilterInput}
          onInputChange={(event, newInputValue) => {
            setClienteFilterInput(newInputValue);
          }}
          getOptionLabel={option => option.nombre}
          getOptionSelected={(option, value) => value._id === option._id}
          options={clientes === null ? "" : clientes}
          filterSelectedOptions
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.nombre}
              size="small"
              {...getTagProps({ index })}
            />
          ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="filled" label="Cliente" placeholder="Cliente" />
          )}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <Autocomplete
          style={{ paddingTop: 8 }}
          id="filter-responsale"
          size="small"
          value={responsableFilter}
          onChange={(event, newValue) => {
            setResponsableFilter(newValue);
          }}
          inputValue={responsableFilterInput}
          onInputChange={(event, newInputValue) => {
            setResponsableFilterInput(newInputValue);
          }}
          getOptionLabel={option => option.nombre + " " + option?.apellido_paterno}
          getOptionSelected={(option, value) => value.run === option.run}
          options={responsables === null ? "" : responsables}
          filterSelectedOptions
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.run}
              size="small"
              {...getTagProps({ index })}
            />
          ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="filled" label="Responsable" placeholder="Responsable" />
          )}
        />
        {/* <Autocomplete
          style={{ paddingTop: 8 }}
          id="filter-responsable"
          size="small"
          getOptionSelected={(option, value) => option.nombre === value.nombre}
          options={responsables === null ? "" : responsables}
          getOptionLabel={(o) => {
            return o?.nombre + " " + o?.apellido_paterno
          }}
          onChange={handleChangeResponsable}
          // defaultValue={responsables[0]}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.codigo}
              size="small"
              {...getTagProps({ index })}
            />
          ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="filled" label="Responsable" placeholder="Responsable" />
          )}
        /> */}
      </Grid>
      <Grid item md={3} xs={12}>
        <Autocomplete
          style={{ paddingTop: 8 }}
          id="filter-contratos"
          size="small"
          options={contratosList}
          getOptionLabel={(option) => {
            return option?.codigo + "-" + option?.nombre
          }}
          // defaultValue={contratosList[0]}
          onChange={handleChangeContrato}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.codigo}
              size="small"
              {...getTagProps({ index })}
            />
          ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="filled" label="Contratos" placeholder="Contratos" />
          )}
        />
      </Grid>
      {/* <Grid item md={2} xs={12}>
        <FormControl variant="filled" margin="dense" size="small" fullWidth>
          <InputLabel id="demo-simple-select-filled-label">Desde</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={desdeFilter}
            onChange={handleChangeDesde}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12}>
        <FormControl variant="filled" margin="dense" size="small" fullWidth>
          <InputLabel id="demo-simple-select-filled-label">Hasta</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={hastaFilter}
            onChange={handleChangeHasta}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Grid> */}
    </Grid>
  )
}

export default FiltroEstadistico;