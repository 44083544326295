import React, { Fragment, useEffect, useContext } from "react";
import { CssBaseline, makeStyles, TextField, Grid, FormControlLabel, Radio, Typography, Button, IconButton, Tooltip, Dialog, DialogContent } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import FormularioContacto from '../component/formularioContacto'

import { obtenerMandante } from "../../../../services/database/mandantes";
import {  envioEncuestaDos, AgregarEncuesta, ObtenerEncuestasPorContratoAndProcesarEstadistica } from "../../../../services/database/encuestas";

import { useSelector } from "react-redux";
import { onPermissionCheck } from "../../../../services/firebase";
import sendEmailDos from '../../../../img/send_email_2.gif'
import { getUserAPI } from "../../../../services/database/usuario";
import { EncuestasContext } from "../../context/encuestaContext";
const style = makeStyles(theme => ({
  main: {
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(2)
  }
}));


function EnvioCorreo(props) {
  const classes = style();
  const {
    contactos,
    mandante,
    idContrato,

  } = props;


  const { usuarioSesion } = useSelector(state => ({
    usuarioSesion: state.usuarioSesion
  }));
  const notistack = useSnackbar();

  const [infoEncuestas, setInfoEncuetas] = useContext(EncuestasContext);



  /**
   * agregar enoder de neter para los correos 
   *  
   */
  const [contactosView, setContactosView] = React.useState(contactos)

  const [mensaje, setMensaje] = React.useState("");
  const [asunto, setAsunto] = React.useState("CyD | Encuesta de satisfacción de clientes")
  const [listaDistribucion, setListaDistribucion] = React.useState([])
  const [openAgregarCliente, setOpenAgregarCliente] = React.useState(false);
  const [sendMailLoading, setSendMailLoading] = React.useState(false);


  useEffect(() => {

  }, [])

  useEffect(() => {
    setContactosView(contactos)
  }, [contactos])

  useEffect(() => {
    let nuevaLista = []

    if (contactosView.length !== 0) {
      nuevaLista.push(contactosView[0])
    }
    setListaDistribucion(nuevaLista);
  }, [contactosView])

  const handleChange = (event) => {

  };

  const handleChangeMensaje = (event) => {
    setMensaje(event.target.value)
  }

  const handleOpenDialogAgregarContacto = (event) => {
    setOpenAgregarCliente(true);
  }

  const handlerCloseDialogAgregarConctato = (event) => {

    setOpenAgregarCliente(false);
  }

  const handlerChangeMandante = async (id) => {

    let mandante = await obtenerMandante(id)

    setContactosView(mandante.responsables)
  }

  const reloadEncuestas = async () => {
    setTimeout(async () => {
      let resultado = await ObtenerEncuestasPorContratoAndProcesarEstadistica(idContrato)
      setInfoEncuetas(resultado);
    }, 1000)

  }

  const handlerClickSendEncuesta = async (id) => {
    setSendMailLoading(true)


    onPermissionCheck().then(async result => {
      console.log(result)
      let de = result.user.displayName + " <" + result.user.email + ">";
      console.log(result.user);
      let resultados = []
      for (var item = 0; item < listaDistribucion.length; item++) {
        var contacto = listaDistribucion[item];
        let correoEnvio = contactosView.find(item => item.contacto.email === contacto.contacto.email);
        let usuarioCyd = await getUserAPI(result.user.uid)
        console.log(usuarioCyd);
        let encuesta = await AgregarEncuesta({
          personas_envio: [{
            nombre: usuarioCyd.nombre,
            apellido_paterno: usuarioCyd.apellido_paterno,
            apellido_materno: usuarioCyd.apellido_materno,
            cargo: usuarioCyd.cargo,
            photo: usuarioCyd.photo,
            contacto: {
              email: result.user.email
            }
          }],
          personas_envio_email: usuarioSesion.email,
          mensaje_envio: mensaje,
          persona_respuesta: correoEnvio,
          persona_respuesta_email: contacto.contacto.email,
          contrato_ref: idContrato,
        })

        
        let resultadoAPI = await envioEncuestaDos(de, contacto.contacto.email, contacto.nombre, asunto, mensaje, encuesta._id)
        resultados.push(resultadoAPI);


      }
      console.log(resultados)

      setSendMailLoading(false)
      reloadEncuestas()
      const errorkey = notistack.enqueueSnackbar("Se ha enviado exitosamente la encuestas.", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: <IconButton onClick={() => notistack.closeSnackbar(errorkey)}><Close /></IconButton>
      });
    }).catch(error => {
      setSendMailLoading(false)
    })


    // enviarEncuesta()
  }




  return (
    <Fragment>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start">
        <Grid item container md={6} xs={12} spacing={3} style={{ padding: 25 }}>
          <Grid
            item
            container
            xs={12}
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid item md={11} xs={11} >
              <Autocomplete
                multiple
                id="tags-outlined"
                size="small"
                value={listaDistribucion}
                options={contactosView}
                onChange={(event, newValue) => {
                  console.log(newValue)
                  // setValue(newValue);
                  setListaDistribucion(newValue)
                }}
                getOptionLabel={(option) => (option?.nombre + ` ` + option.apellido_paterno) + `<${option?.contacto.email}>`}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Cliente(s)"
                    placeholder=""
                  />
                )}
              />
            </Grid>
            <Grid item md={1} xs={1}>
              <Tooltip title="Add" aria-label="Agregar Cliente">
                <IconButton aria-label="delete" onClick={handleOpenDialogAgregarContacto}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>

          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              id="outlined-name"
              label="Asunto"
              value={asunto}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Cuerpo adicional del mensaje"
              multiline
              rows={10}
              onChange={handleChangeMensaje}
              defaultValue={mensaje}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item container md={6} xs={12} spacing={3} style={{ padding: 25 }}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start">
            Borrador del mensaje
            <Grid item style={{
              // background: "#a64c46",
              padding: "10px",
              borderColor: "black",
              borderRadius: "10px",
              border: "2px solid #731f1f",
            }} >
              <Typography variant="body1" gutterBottom align="justify" >
              Junto con saludar, le solicitamos nos ayude <strong> completando la siguiente encuesta de satisfacción de clientes</strong>, la cual consiste en <strong>responder una pregunta</strong> y entregarnos sus<strong>comentarios
                                    </strong> que tenga sobre nuestro trabajo.</Typography><br></br>
              <Typography variant="body1" gutterBottom align="justify" >Esto nos permitirá <strong>mejorar el
                                      servicio</strong>  que les brindamos a ustedes y otros clientes. </Typography><br></br>
              <Typography variant="body1" gutterBottom align="justify" ><br></br>
                {
                  mensaje.split("\n").map(function (item, idx) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    )
                  })
                }
              </Typography><br></br>
              <Typography variant="body1" gutterBottom align="justify" >En una escala del 1 al 10, siendo 1 <strong> muy improbable</strong> y 10  <strong>muy probable</strong>,¿qué tan
              probable es que usted recomiende los servicios de CyD Ingeniería a colegas o terceros?
              </Typography>

              <br></br>
              {/* <div style={{ maxWidth: "600px" }}> */}
              <div >
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="1"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="2"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="3"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="4"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="5"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="6"
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="7"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="8"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="9"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Radio size="small" style={{ padding: 3 }} color="primary" />}
                  label="10"
                  labelPlacement="top"
                />

              </div>
              <p>Comentario:</p>
              <textarea style={{ width: "100%", maxWidth: "600px" }} id="w3review" name="w3review" rows="4" ></textarea>
            </Grid>


          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
        style={{ padding: 25 }}
        alignItems="flex-end">
        <Grid item>
          <Button onClick={handlerClickSendEncuesta} variant="contained" color="secondary">
            Enviar Encuesta
              </Button>
        </Grid>
      </Grid>
      <FormularioContacto onMandanteChange={handlerChangeMandante} mandante={mandante} openAgregarCliente={openAgregarCliente} handleCloseModal={handlerCloseDialogAgregarConctato} />
      <Dialog
        open={sendMailLoading}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        PaperProps={{
          classes: {
            root: classes.corner
          },

        }}


      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Grid item >
              <div>
                <img alt="logo" src={sendEmailDos} width={150} />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Typography variant="overline" display="block" align="center" style={{ lineHeight: 1.3 }} gutterBottom>
              Enviado correo ...
						</Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment >
  );
}

export default EnvioCorreo;
