import React, { useEffect, useState } from "react";
import Page from "./page";
import { obtenerSociedad, actualizarSociedad } from "../requestSociedad";
import { useHistory, useParams } from "react-router-dom";
import { object, string } from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

function Index(props) {
	const [Sociedad, SetSociedad] = useState();

	const { id: sociedadID } = useParams();
	const history = useHistory();
	const notistack = useSnackbar();

	useEffect(() => {
		obtenerSociedad(sociedadID)
			.then(sociedad => SetSociedad(sociedad))
			.catch(error => {
				console.error(error);
				SetSociedad({});
			});
	}, []);

	/**
	 * Valores iniciales del formulario.
	 */
	const initials = {
		_id: Sociedad ? Sociedad._id : "",
		codigo: Sociedad ? Sociedad.codigo : "",
		rut: Sociedad ? Sociedad.rut : "",
		nombre: Sociedad ? Sociedad.nombre : "",
		giro: Sociedad ? Sociedad.giro : "",
		contacto: {
			telefono: Sociedad && Sociedad.contacto ? Sociedad.contacto.telefono : "",
			telefono_alternativo: Sociedad && Sociedad.contacto ? Sociedad.contacto.telefono_alternativo : "",
			fax: Sociedad && Sociedad.contacto ? Sociedad.contacto.fax : "",
		},
		ubicacion: {
			direccion: Sociedad && Sociedad.ubicacion ? Sociedad.ubicacion.direccion : "",
			comuna: Sociedad && Sociedad.ubicacion ? Sociedad.ubicacion.comuna : "",
			region: Sociedad && Sociedad.ubicacion ? Sociedad.ubicacion.region : "",
		},
	}

	/**
	 * Esquema de validación para la sociedad.
	 */
	const validation = object().shape({
		codigo: string()
			.min(3, "El código debe tener al menos ${min} caracteres.")
			.max(10, "El código debe tener a lo más ${max} caracteres.")
			.required("El código es requerido."),
		rut: string()
			.matches(/^(\d{1,2})\.?(\d{3})\.?(\d{3})-[[\dkK]$/, "El formato del RUT no es válido.")
			.required("El RUT es requerido."),
		nombre: string()
			.min(3, "El nombre debe tener al menos ${min} caracteres.")
			.max(50, "El nombre debe tener a lo más ${max} caracteres.")
			.required(),
		giro: string()
			.min(3, "El giro debe tener al menos ${min} caracteres.")
			.max(50, "El giro debe tener a lo más ${max} caracteres.")
			.required("El giro es requerido."),
		contacto: object()
			.shape({
				telefono: string()
					.min(6, "El teléfono debe tener al menos ${min} números.")
					.max(12, "El teléfono debe tener a lo más ${max} números.")
					.optional(),
				telefono_alternativo: string()
					.min(6, "El teléfono alternativo debe tener al menos ${min} números.")
					.max(12, "El teléfono alternativo debe tener a lo más ${max} números.")
					.optional(),
				fax: string()
					.min(6, "El fax debe tener al menos ${min} números.")
					.max(20, "El fax debe tener a lo más ${max} números.")
					.optional(),
			})
			.optional(),
		ubicacion: object()
			.shape({
				direccion: string()
					.min(3, "La dirección debe tener al menos ${min} caracteres.")
					.max(60, "La dirección debe tener a lo más ${max} caracteres.")
					.optional(),
				comuna: string()
					.min(3, "La comuna debe tener al menos ${min} caracteres.")
					.max(50, "La comuna debe tener a lo más ${max} caracteres.")
					.optional(),
				region: string()
					.min(3, "La región debe tener al menos ${min} caracteres.")
					.max(50, "La región debe tener a lo más ${max} caracteres.")
					.optional(),
			})
			.optional(),
	});

	/**
	 * Configuración de Formik Hook.
	 */
	const formik = useFormik({
		initialValues: initials,
		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, helper) => handleActualizar(values),
	});

	/**
	 * Handler para actualizar sociedad.
	 */
	const handleActualizar = (values) => {
		actualizarSociedad(values)
			.then(response => {
				console.log("Sociedad actualizada exitosamente.", response);
				let mensajeExito = notistack.enqueueSnackbar("Sociedad actualizada exitosamente.", {
					variant: "success",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeExito)}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error("Error al intentar actualizar la sociedad.", error);
				let mensajeError = notistack.enqueueSnackbar("Error al intentar actualizar la sociedad.", {
					variant: "error",
					anchorOrigin: { vertical: "bottom", horizontal: "center" },
					action: <IconButton onClick={() => notistack.closeSnackbar(mensajeError)}><Close /></IconButton>
				});
			})
			.finally(() => {
				history.push("/sociedades");
			});
	}

	/**
	 * Handler para salir del formulario.
	 */
	const handleSalir = () => {
		history.push("/sociedades");
	}

	return (
		<Page
			handle_salir={handleSalir}
			formik={formik}
		/>
	);
}

export default Index;