import React, { useState } from "react";
import MaterialTable, { MTableAction } from "material-table";
// import AgregarPerfil from "../agregarPerfil";
import { Button, Dialog, DialogActions } from "@material-ui/core";
import MenuDropdown from "../../../../components/menuDropDown";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";

// import EditarPerfil from "../editPerfil";
// import EliminarPerfil from "../eliminarPerfil";

const location = require('../../../../data/location.json');


function Page(props) {
  const { data, isLoading, onEntered, onClose, open, onClickOpenAddPerfilRol,
    onClickDeletePerfilRol } = props;
  const [pageSize, setPageSize] = useState(10);
  return (
    <Dialog
      open={open}
      onEntered={onEntered}
      maxWidth={"md"}
      fullWidth={true}
      onClose={onClose}
    >
      <MaterialTable
        title={`Listado de Perfiles`}
        data={data}
        options={{
          filtering: false,
          pageSizeOptions: [5, 10, 20, 50],
          pageSize: pageSize,
          grouping: false,
          columnsButton: true,
          maxBodyHeight: "70vh",
          headerStyle: {
            textAlign: 'center',
            border: "1px solid #E0E0E0",
            fontWeight: 'bold',
            color: '#731f1f',
            position: 'sticky'
          },
          rowStyle: {
            border: "1px solid #E0E0E0"
          },
          padding: 'dense',
          actionsCellStyle: {
            width: "2%",
            textAlign: "center",
            border: "1px solid #E0E0E0",
          },
          cellStyle: {
            border: "1px solid #E0E0E0",
          }
        }}
        onChangeRowsPerPage={size => { setPageSize(size) }}
        localization={location}
        isLoading={isLoading}
        columns={[
          {
            title: 'Nombre',
            field: 'nombre',
          },
          {
            title: 'Descripción',
            field: 'descripcion',
          }
        ]}
        actions={[
          {
            icon: "settings",
            onClick: (evt) => { }
          },
          {
            icon: "agregar",
            isFreeAction: true,
            onClick: (evt) => { }
          },
        ]}
        components={{
          Action:
            props => {
              const rowData = props.data;
              if (props.action.icon === 'agregar') {
                return (
                  <Button
                    variant="outlined"
                    onClick={(evt) => onClickOpenAddPerfilRol(evt, null)}
                  >
                    Agregar Perfil
                  </Button>
                );
              }
              if (props.action.icon === 'settings') {
                const accionesConfiguracion = [
                  {
                    content: 'Eliminar',
                    onClick: (e) => onClickDeletePerfilRol(rowData)
                  }
                ];

                return (<MenuDropdown
                  icon={
                    <MoreVertIcon />
                  }
                  tooltip="Opciones"
                  items={accionesConfiguracion}
                  hidden={accionesConfiguracion.length <= 0}
                />);
              }
              return (<MTableAction {...props} />);
            }
        }}
      />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Page;