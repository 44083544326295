import React, { Fragment } from "react";
import { Breadcrumbs, Paper, Grid, TextField, Typography, makeStyles, Box, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";

function Page(props) {
	const {
		responsables,
		handle_salir,
		formik,
		is_open,
		handler_agregar_responsable,
		handle_show_dialog,
		handle_hide_dialog,
	} = props;

	const classes = useStyle();
	const { values, handleChange, setFieldValue, submitForm, handleSubmit, isSubmitting, errors, touched } = formik;

	if (!responsables) {
		//Si aún no cargan los datos.
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Mandantes</Typography>
				<Link to="/mandantes">Mandantes</Link>
				<Typography color="textPrimary">{`Modificar Mandante ${values.nombre}`}</Typography>
			</Breadcrumbs>
			<Paper className={classes.card}>
				<form onSubmit={handleSubmit}>
					{/* CAMPOS DEL MANDANTE */}
					<Grid container spacing={3}>
						<Grid item xs={6} xl={4}>
							{/* NOMBRE */}
							<TextField
								name="nombre"
								label="Nombre"
								value={values.nombre}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
								helperText={touched.nombre && errors.nombre ? errors.nombre : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* RUT */}
							<TextField
								name="rut"
								label="RUT"
								value={values.rut}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.rut && errors.rut && Boolean(errors.rut)}
								helperText={touched.rut && errors.rut ? errors.rut : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* GIRO */}
							<TextField
								name="giro"
								label="Giro"
								value={values.giro}
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.giro && errors.giro && Boolean(errors.giro)}
								helperText={touched.giro && errors.giro ? errors.giro : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* TIPO */}
							<Autocomplete
								name="tipo"
								value={["Sin Tipo", "Público", "Privado"].find(e => e.toLocaleLowerCase() === values.tipo?.toLocaleLowerCase())}
								options={["Público", "Privado"]}
								onChange={(event, value) => setFieldValue("tipo", value ? value : "")}
								renderInput={(params) => (
									<TextField
										label="Tipo"
										variant="outlined"
										required
										error={touched.tipo && errors.tipo && Boolean(errors.tipo)}
										helperText={touched.tipo && errors.tipo ? errors.tipo : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* BOTÓN PARA AGREGAR RESPONSABLE POPUP */}
							<Button onClick={handle_show_dialog} variant="outlined" fullWidth className={classes.button_agregar_responsable}>Agregar Responsable</Button>
							{touched.responsables && errors.responsables && (
								<Typography variant="caption" color="error" className={classes.mensaje_error_responsable}>{errors.responsables}</Typography>
							)}
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* RESPONSABLE ACTUAL */}
							<Autocomplete
								name="responsable_actual"
								value={Array.from(responsables).find(r => values.responsable_actual && r.contacto.email.toLocaleLowerCase() === values.responsable_actual.contacto.email.toLocaleLowerCase())}
								loading={!responsables}
								options={responsables}
								onChange={(event, value) => setFieldValue("responsable_actual", value ? value : "")}
								getOptionLabel={r => r.nombre_completo}
								renderInput={(params) => (
									<TextField
										label="Responsable Actual"
										variant="outlined"
										error={touched.responsable_actual && errors.responsable_actual && Boolean(errors.responsable_actual)}
										helperText={touched.responsable_actual && errors.responsable_actual ? errors.responsable_actual : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Box display="flex" flexDirection="row-reverse">
						<Button onClick={handle_salir} variant="outlined" color="primary" className={classes.button}>Salir</Button>
						<Button onClick={submitForm} variant="contained" color="primary" className={classes.button}>Actualizar</Button>
					</Box>
				</form>
			</Paper>

			{/* DIALOG PARA AGREGAR RESPONSABLE */}
			<Dialog open={is_open} onClose={handle_hide_dialog} >
				<DialogTitle>Responsable</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>Agregar información de contacto de cliente. Los campos marcados  (*) son requeridos.</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.nombre"
								label="Nombre"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.nombre && errors.responsable_nuevo && errors.responsable_nuevo.nombre && Boolean(errors.responsable_nuevo.nombre)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.nombre && errors.responsable_nuevo && errors.responsable_nuevo.nombre ? errors.responsable_nuevo.nombre : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.apellido_paterno"
								label="Apellido Paterno"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.apellido_paterno && errors.responsable_nuevo && errors.responsable_nuevo.apellido_paterno && Boolean(errors.responsable_nuevo.apellido_paterno)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.apellido_paterno && errors.responsable_nuevo && errors.responsable_nuevo.apellido_paterno ? errors.responsable_nuevo.apellido_paterno : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.apellido_materno"
								label="Apellido Materno"
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.apellido_materno && errors.responsable_nuevo && errors.responsable_nuevo.apellido_materno && Boolean(errors.responsable_nuevo.apellido_materno)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.apellido_materno && errors.responsable_nuevo && errors.responsable_nuevo.apellido_materno ? errors.responsable_nuevo.apellido_materno : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.cargo"
								label="Cargo"
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.cargo && errors.responsable_nuevo && errors.responsable_nuevo.cargo && Boolean(errors.responsable_nuevo.cargo)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.cargo && errors.responsable_nuevo && errors.responsable_nuevo.cargo ? errors.responsable_nuevo.cargo : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.contacto.email"
								label="Email"
								variant="outlined"
								fullWidth
								required
								type="email"
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.contacto.email && errors.responsable_nuevo && errors.responsable_nuevo.contacto.email && Boolean(errors.responsable_nuevo.contacto.email)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.contacto.email && errors.responsable_nuevo && errors.responsable_nuevo.contacto.email ? errors.responsable_nuevo.contacto.email : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.contacto.telefono"
								label="Teléfono"
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.contacto.telefono && errors.responsable_nuevo && errors.responsable_nuevo.contacto.telefono && Boolean(errors.responsable_nuevo.contacto.telefono)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.contacto.telefono && errors.responsable_nuevo && errors.responsable_nuevo.contacto.telefono ? errors.responsable_nuevo.contacto.telefono : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							<TextField
								name="responsable_nuevo.telefono_personal"
								label="Teléfono Personal"
								variant="outlined"
								fullWidth
								onChange={handleChange}
								error={touched.responsable_nuevo && touched.responsable_nuevo.telefono_personal && errors.responsable_nuevo && errors.responsable_nuevo.telefono_personal && Boolean(errors.responsable_nuevo.telefono_personal)}
								helperText={touched.responsable_nuevo && touched.responsable_nuevo.telefono_personal && errors.responsable_nuevo && errors.responsable_nuevo.telefono_personal ? errors.responsable_nuevo.telefono_personal : ""}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handler_agregar_responsable} variant="contained" color="primary" disabled={ValidarResposableNuevo(values.responsable_nuevo)}>Agregar</Button>
					<Button onClick={handle_hide_dialog} variant="outlined" color="primary">Cancelar</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

/**
 * Método encargado de validar si un nuevo responsable tiene todos sus datos requeridos.
 * @param {*} responsableNuevo Nuevo responsable.
 */
function ValidarResposableNuevo(responsableNuevo) {
	if (!responsableNuevo.nombre) {
		return true;
	}
	if (!responsableNuevo.apellido_paterno) {
		return true;
	}
	if (!responsableNuevo.cargo) {
		return true;
	}
	if (!responsableNuevo.contacto.email || !validateEmail(responsableNuevo.contacto.email)) {
		return true;
	}
	return false;
}

/**
 * Método encargado de validar el formato de un email.
 * https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 * @param {*} email Email que será validado.
 */
function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

const useStyle = makeStyles((theme) => ({
	card: {
		padding: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(1),
	},
	button_agregar_responsable: {
		height: "100%",
	},
	mensaje_error_responsable: {
		marginLeft: theme.spacing(2),
	},
}));

export default Page;