import React, { Fragment } from "react";
import { Breadcrumbs, Paper, Grid, TextField, Typography, makeStyles, Box, Button, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Link } from "react-router-dom";

function Page(props) {
	const {
		responsables,
		handle_limpiar,
		formik,
	} = props;

	const classes = useStyle();
	const { values, handleChange, setFieldValue, submitForm, handleSubmit, isSubmitting, errors, touched } = formik;

	if (!responsables) {
		//Si aún no cargan los datos.
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Gerencias</Typography>
				<Link to="/gerencias">Gerencias</Link>
				<Typography color="textPrimary">Ingresar Gerencia</Typography>
			</Breadcrumbs>
			<Paper className={classes.card}>
				<form onSubmit={handleSubmit}>
					{/* CAMPOS DE LA GERENCIA */}
					<Grid container spacing={3}>
						<Grid item xs={6} xl={4}>
							{/* NOMBRE */}
							<TextField
								name="nombre"
								label="Nombre"
								value={values.nombre}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.nombre && errors.nombre && Boolean(errors.nombre)}
								helperText={touched.nombre && errors.nombre ? errors.nombre : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={4}>
							{/* SIGLA */}
							<TextField
								name="sigla"
								label="Sigla"
								value={values.sigla}
								variant="outlined"
								fullWidth
								required
								onChange={handleChange}
								error={touched.sigla && errors.sigla && Boolean(errors.sigla)}
								helperText={touched.sigla && errors.sigla ? errors.sigla : ""}
							/>
						</Grid>
						<Grid item xs={6} xl={3}>
							{/* RESPONSABLE */}
							<Autocomplete
								name="responsable_ref"
								loading={!responsables}
								options={responsables}
								onChange={(event, value) => setFieldValue("responsable_ref", value ? value._id : "")}
								getOptionLabel={responsable => responsable.persona.nombre_completo}
								renderInput={(params) => (
									<TextField
										label="Responsable"
										variant="outlined"
										required
										error={touched.responsable_ref && errors.responsable_ref && Boolean(errors.responsable_ref)}
										helperText={touched.responsable_ref && errors.responsable_ref ? errors.responsable_ref : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Box display="flex" flexDirection="row-reverse">
						<Button onClick={handle_limpiar} variant="outlined" color="primary" className={classes.button}>Limpiar</Button>
						<Button onClick={submitForm} variant="contained" color="primary" className={classes.button}>Agregar</Button>
					</Box>
				</form>
			</Paper>
		</Fragment>
	);
}

const useStyle = makeStyles((theme) => ({
	card: {
		padding: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(1),
	}
}));

export default Page;