import Axios from "axios";

const URL_BASE = "https://api-portafolio.cydocs.cl";

/**
 * Método encargado de obtener los gerencias.
 */
export const ObtenerGerencias = async () => {
	return new Promise((resolve, reject) => {
		Axios.get(`${URL_BASE}/gerencias`, {})
			.then(res => {
				if (res.data) {
					//Si existen datos, se retornan.
					resolve(res.data);
				} else {
					//Si no existen datos, se retorna NULL.
					resolve(null);
				}
			})
			.catch(error => {
				//Si se generó un error, se muestra por consola y se retorna.
				console.error(error);
				reject(error);
			});
	});

}

/**
 * Método encargado de agregar un gerencia.
 * @param {*} gerencia Gerencia con los datos nuevos.
 */
export const AgregarGerencia = async (gerencia) => {
	try {
		let response = await Axios.post(`${URL_BASE}/gerencias`, gerencia);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un gerencia.
 * @param {*} gerencia Gerencia con los datos actualizados.
 */
export const ActualizarGerencia = async (gerencia) => {
	try {
		let response = await Axios.put(`${URL_BASE}/gerencias/${gerencia._id}`, gerencia);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un gerencia.
 * @param {*} gerenciaID ID del gerencia.
 */
export const EliminarGerencia = async (gerenciaID) => {
	try {
		let response = await Axios.delete(`${URL_BASE}/gerencias/${gerenciaID}`);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}