import React, { Fragment } from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "../../../components/material_table";

function Page(props) {
	const {
		gerencias,
		handle_export,
	} = props;

	const history = useHistory();

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Sigla",
			field: "sigla",
		}, {
			title: "Responsable",
			field: "responsable_ref.persona.nombre_completo",
		}
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => history.push(`/gerencia/${row._id}`),
			iconProps: { style: { color: "#757575" } },
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => alert("Eliminar Gerencia"),
			iconProps: { style: { color: "#757575" } },
			hidden: true, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "add",
			tooltip: "Agregar",
			onClick: () => history.push("/gerencia"),
			isFreeAction: true,
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		},
	];

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Gerencias</Typography>
				<Typography color="textPrimary">Listado Gerencias</Typography>
			</Breadcrumbs>
			<MaterialTable
				title="Listado de Gerencias"
				data={gerencias}
				is_loading={!gerencias}
				columns={columns}
				actions={actions}
				export_function={handle_export}
			/>
		</Fragment>
	);
}

export default Page;