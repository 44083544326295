import React, { useEffect } from "react";
import { realTime } from '../../../services/firebase'
import { obtenerContrato, obtenerContratos, obtenerContratosPorGerencia } from "../../../services/database/contratos";
import * as PERMISSIONS from '../../../constants/permissions';
import { tienePermisosParaVerTodosContrato, tienePermisoParaVerContratosEncargado } from "../../../services/database/usuario";

export const useContratosVigentesRealTime = () => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [contratos, setContratos] = React.useState([])

    useEffect(() => {
        try {
            realTime.ref('contrato').orderByChild("centro_costo").equalTo(false).once('value', async snapshot => {
                const contratosValue = snapshot.val();
                if (contratosValue) {
                    let contratosList = Object.keys(contratosValue).map(key => {
                        return {
                            ...contratosValue[key],
                            id: key,
                        };
                    });
                    let contratoFiltrado = contratosList.filter((value) => {
                        if (value.estado === "activo") {
                            return value;
                        }
                    })
                    

                    setContratos(contratoFiltrado);
                    setLoading(false);
                } else {
                    this.setState({ mandantes: null, loading: false });
                }

            });
        } catch (error) {
            setError(error);
        }
    },
        []
    )

    return {
        error,
        loading,
        contratos,
    }
}

export const useContratosVigentes = (usuario) => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [contratos, setContratos] = React.useState([])

 

    useEffect(() => {

        obtenerContratos().then(result => {
            let filtrado = result.map(item => {
                let nombre_display = item.responsable?.nombre + " " + item?.responsable?.apellido_paterno + " " + item?.responsable?.apellido_materno
                
                if(item.responsable){
                    item.responsable["nombre_display"] = nombre_display;
                }
                
                return item;
            })
            if (tienePermisosParaVerTodosContrato(usuario)) {
                setContratos(filtrado)
            } else if (tienePermisoParaVerContratosEncargado(usuario)) {
                let filtroUsuario = []
                if (usuario.email) {
                    filtroUsuario = filtrado.filter((contrato) => {
                        return contrato.responsable.contacto.email === usuario.email
                    })
                }
                setContratos(filtroUsuario)
            }

            setLoading(false);
        })


    }, [])

    return {
        error,
        loading,
        contratos,
    }
}

export const useContrato = (id) => {
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [contrato, setContrato] = React.useState(null)



    useEffect(() => {
        obtenerContrato(id).then((result) => {
            setContrato(result)
            setLoading(false);
        }).catch(error => {
            setError(true)
        })
    }, [id])

    return {
        error,
        loading,
        contrato,
    }
}


export const useContratosFilter = (gerencia = null, responsable = null) => {

    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [contratos, setContratos] = React.useState([])

    useEffect(() => {
        if (gerencia === "all_gerencias" || gerencia.sigla === "GGN") {
            obtenerContratos().then(result => {
                if (result) {
                    let filtrado = result.map(item => {
												let nombre_display = item?.responsable?.nombre + " " + item?.responsable?.apellido_paterno + " " + item?.responsable?.apellido_materno;
												item["responsable"] = item.responsable_ref.persona;
                        item.responsable["nombre_display"] = item.responsable_ref.persona.nombre_completo;
                        return item;
                    })
                    setContratos(filtrado)
                }

                setLoading(false);
            })
        } else {

            obtenerContratosPorGerencia(gerencia).then(result => {
                if (result) {
                    let filtrado = result.map(item => {
                        let nombre_display = item.responsable.nombre + " " + item.responsable.apellido_paterno + " " + item.responsable.apellido_materno
                        item.responsable["nombre_display"] = nombre_display;
                        return item;
                    })
                    setContratos(filtrado)
                }

                setLoading(false);
            })
        }




    }, [gerencia, responsable])

    return {
        error,
        loading,
        contratos,
    }
}