import {
  ACTION_REFRESH_TABLE_PERMISO,
  ACTION_OPEN_ADD_PERMISO,
  ACTION_OPEN_DELETE_PERMISO,
  ACTION_OPEN_EDIT_PERMISO
} from './actionsPermisos';

const defaultState = {
  refreshTablePermiso: true,
  openAddPermiso: false,
  openDeletePermiso: false,
  openEditPermiso: false,
  permisoSeleccionado: null

};

export default function reducerPermiso(state = defaultState, {
  type,
  refreshTablePermiso,
  openAddPermiso,
  openDeletePermiso,
  openEditPermiso,
  permisoSeleccionado
}) {

  switch (type) {
    case ACTION_REFRESH_TABLE_PERMISO: {
      return {...state, refreshTablePermiso};
    }
    case ACTION_OPEN_ADD_PERMISO: {
      return {...state, openAddPermiso};
    }
    case ACTION_OPEN_DELETE_PERMISO: {
      return {...state, openDeletePermiso, permisoSeleccionado: permisoSeleccionado};
    }
    case ACTION_OPEN_EDIT_PERMISO: {
      return {...state, openEditPermiso, permisoSeleccionado: permisoSeleccionado};
    }
    default:
      return state;
  }
}