import React, { useState, useEffect } from "react";
import Page from "./page";
import { obtenerResponsables } from "../requestResponsable";
import XLSX from "xlsx";

function Index(props) {
	const [Responsables, SetResponsables] = useState();

	useEffect(() => {
		obtenerResponsables()
			.then(responsables => SetResponsables(responsables))
			.catch(error => {
				console.error(error);
				SetResponsables([]);
			});
	}, []);

	const handleExport = () => {
		const wb = XLSX.utils.book_new();
		let exportData = Responsables.map(r => ({
			nombre: r.persona.nombre_completo,
			run: r.persona.run,
			correlativo: r.correlativo,
		}));
		let ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, "Mandantes");
		XLSX.writeFile(wb, "mandantes.xlsx");
	}

	return (
		<Page
			responsables={Responsables}
			handle_export={handleExport}
		/>
	);
}

export default Index;