import React, { Fragment } from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MaterialTable from "../../../components/material_table";

function Page(props) {
	const {
		mandantes,
		handle_export,
	} = props;

	const history = useHistory();

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "RUT",
			field: "rut_formateado",
		}, {
			title: "Responsable",
			field: "responsable_actual.nombre_completo",
		}, {
			title: "Tipo",
			field: "tipo",
		}, {
			title: "Giro",
			field: "giro",
		}
	];

	let actions = [
		{
			icon: "edit",
			tooltip: "Editar",
			onClick: (event, row) => history.push(`/mandante/${row._id}`),
			iconProps: { style: { color: "#757575" } },
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => alert("Eliminar Contrato"),
			iconProps: { style: { color: "#757575" } },
			hidden: true, //Verificar permisos
			disabled: false, //Verificar permisos
		}, {
			icon: "add",
			tooltip: "Agregar",
			onClick: () => history.push("/mandante"),
			isFreeAction: true,
			hidden: false, //Verificar permisos
			disabled: false, //Verificar permisos
		},
	];

	return (
		<Fragment>
			<Breadcrumbs separator=">">
				<Typography color="textPrimary">Módulo Mandantes</Typography>
				<Typography color="textPrimary">Listado Mandantes</Typography>
			</Breadcrumbs>

			<MaterialTable
				title="Listado de Mandantes"
				is_loading={!mandantes}
				data={mandantes}
				columns={columns}
				actions={actions}
				export_function={handle_export}
			/>
		</Fragment>
	);
}

export default Page;