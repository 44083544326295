import React, { useEffect, useState } from "react";
import Page from "./page";
import { getAllPermiso } from "../requestPermisos";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { actionOpenAddPermiso, actionRefreshTablePermiso, actionOpenDeletePermiso, actionOpenEditPermiso } from "../actionsPermisos";
export default function ListadoPermisos() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { resfreshTable } = useSelector(state => ({
    resfreshTable: state.reducerPermiso.refreshTablePermiso
  }));

  const cargarData = () => {

    const CancelToken = axios.CancelToken;
    let cancelRequest = null;
    let cancelToken = new CancelToken(function executor(c) {
      cancelRequest = c;
    });

    getAllPermiso({}, cancelToken)
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setData(response.data);

        } else if (response.status === 204) {
          setIsLoading(false);
          setData([]);
        } else {
          setIsLoading(false);
          setData([]);
        }
        dispatch(actionRefreshTablePermiso(false));
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setData([]);
      });

    return cancelRequest;
  };


  const onClickOpenAddPermiso = () => dispatch(actionOpenAddPermiso(true));
  const onClickOpenDeletePermiso = (rowData) => dispatch(actionOpenDeletePermiso(true, rowData));
  const onClickOpenEditPermiso = (rowData) => dispatch(actionOpenEditPermiso(true, rowData));

  useEffect(() => {
    if (resfreshTable || isLoading) {
      setIsLoading(true);
      const cancelRequest = cargarData();
      return () => {
        if (cancelRequest) cancelRequest();
      }
    }
  }, [resfreshTable]);


  return (
    <Page
      data={data}
      isLoading={isLoading}
      onClickOpenAddPermiso={onClickOpenAddPermiso}
      onClickOpenDeletePermiso={onClickOpenDeletePermiso}
      onClickOpenEditPermiso={onClickOpenEditPermiso}
    />
  );
}