import Axios from "axios";

const BASE_URL = process.env.REACT_APP_DB_URL;

/**
 * Método encargado de obtener todos los mandantes.
 */
export async function obtenerMandantes() {
	try {
		let url = `${BASE_URL}/mandantes`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener un mandante, por su ID.
 * @param {*} mandanteID ID del mandante.
 */
export async function obtenerMandante(mandanteID) {
	try {
		let url = `${BASE_URL}/mandantes/${mandanteID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un mandante.
 * @param {*} mandante Datos del mandante.
 */
export async function agregarMandante(mandante) {
	try {
		let url = `${BASE_URL}/mandantes`;
		let response = await Axios.post(url, mandante);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un mandante.
 * @param {*} mandante Datos actualizados del mandante.
 */
export async function actualizarMandante(mandante) {
	try {
		let url = `${BASE_URL}/mandantes/${mandante._id}`;
		let response = await Axios.put(url, mandante);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un mandante.
 * @param {*} mandanteID ID del mandante.
 */
export async function eliminarMandante(mandanteID) {
	try {
		let url = `${BASE_URL}/mandantes/${mandanteID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

////////////////////////////////////////////////////////////////////

/**
 * Método encargado de agregar un responsable a un mandante, por su ID.
 * @param {*} mandanteID ID del mandante.
 * @param {*} responsable Datos del responsable.
 */
export async function AgregarResponsable(mandanteID, responsable) {
	try {
		let url = `${BASE_URL}/mandantes/${mandanteID}/responsables`;
		let response = await Axios.post(url, responsable);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener los responsables de un mandante, por su ID.
 * @param {*} mandanteID ID del mandante.
 */
export async function ObtenerResponsablesPorID(mandanteID) {
	try {
		let url = `${BASE_URL}/mandantes/${mandanteID}/responsables`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}