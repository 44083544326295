import {
  ACTION_REFRESH_TABLE_ROL,
  ACTION_REFRESH_TABLE_PERFILES_ROL,
  ACTION_OPEN_ADD_PERFIL_ROL,
  ACTION_OPEN_VIEW_PERFIL_ROL
} from './actionsRoles';

const defaultState = {
  refreshTableRol: true,
  refreshTablePerfilesRol: false,
  openAddPerfilRol: false,
  openViewPerfilRol: false,
  rolSeleccionado: null

};

export default function reducerPerfil(state = defaultState, {
  type,
  refreshTableRol,
  refreshTablePerfilesRol,
  openAddPerfilRol,
  openViewPerfilRol,
  rolSeleccionado,
}) {

  switch (type) {
    case ACTION_REFRESH_TABLE_ROL: {
      return { ...state, refreshTableRol };
    }
    case ACTION_REFRESH_TABLE_PERFILES_ROL: {
      return { ...state, refreshTablePerfilesRol };
    }
    case ACTION_OPEN_ADD_PERFIL_ROL: {
      return { ...state, openAddPerfilRol};
    }
    case ACTION_OPEN_VIEW_PERFIL_ROL:{
      return { ...state, openViewPerfilRol, rolSeleccionado: rolSeleccionado };
    }
    default:
      return state;
  }
}